import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { HostInvitation } from "@/model/host-invitation.model";

export const API_PATH = "/host-invitations";

export default {

  namespaced: true,
  state: {

    currentPageLoading: false,
    displayUserInvitations: [],
    currentPage: {
      userInvitations: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageUserInvitations(state, userInvitationsDto) {
      state.currentPage.userInvitations = [...userInvitationsDto];
      state.displayUserInvitations = state.currentPage.userInvitations.map(HostInvitation.fromDto);
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {
    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount");

        const userInvitationsDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageUserInvitations", userInvitationsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async acceptUserInvitation({ dispatch, state }, invitation) {
      state.currentPageLoading = true;

      const body = await bodyWithAuthHeader();

      try {
        await API.post("core", `${API_PATH}/${invitation.id}/accept`, body);
        dispatch("reloadPage");
        Vue.toasted.success(`Invitation for ${invitation.roles.map(it => it.text).join(", ")} of "${invitation.host.name}" successfully accepted!`, {
          duration: 5000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        state.currentPageLoading = false;
      }
    },

    async declineUserInvitation({ commit, dispatch, state }, invitation) {
      state.currentPageLoading = true;

      const body = await bodyWithAuthHeader();

      try {
        await API.post("core", `${API_PATH}/${invitation.id}/decline`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(`Invitation for ${invitation.roles.map(it => it.text).join(", ")} of "${invitation.host.name}" successfully declined!`, {
          duration: 5000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        state.currentPageLoading = false;
      }
    },
  },
};
