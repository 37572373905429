import {
  SUPER_ADMIN_USER_TYPE,
  SUPER_ADMIN_DISPLAY_USER_TYPE,
  STANDARD_USER_TYPE,
  STANDARD_DISPLAY_USER_TYPE,
} from "@/utils/utils";
import { Host } from "@/model/host.model";
import { SimpleUserDto } from "@/model/types";

export class SimpleUser {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public type: string,
    public hosts: Host[],
    public createdAt: string,
    public updatedAt: string,
  ) {
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static fromDto(userDto: SimpleUserDto): SimpleUser {
    return new SimpleUser(
      userDto.id,
      userDto.firstName,
      userDto.lastName,
      userDto.email,
      userDto.userType,
      (userDto.hosts || []).map(Host.fromDto),
      userDto.createdAt,
      userDto.updatedAt,
    );
  }

  get isSuperAdmin(): boolean {
    return this.type === SUPER_ADMIN_USER_TYPE;
  }

  get isStandard(): boolean {
    return this.type === STANDARD_USER_TYPE;
  }

  getUserTypeDisplayName(): string | undefined {
    switch (this.type) {
      case SUPER_ADMIN_USER_TYPE:
        return SUPER_ADMIN_DISPLAY_USER_TYPE;
      case STANDARD_USER_TYPE:
        return STANDARD_DISPLAY_USER_TYPE;
      default:
        return undefined;
    }
  }
}
