import {
  EmailAttributes, EmailTemplateDto, EmailToDto, EmailType,
} from "@/model/email/types";

const EMAIL_ROOT_TEMPLATE_TYPE = "ROOT_TEMPLATE";

export class EmailTemplate {
  public updatedBody = "";

  public updatedSubject = "";

  constructor(
    public body: string,
    public subject: string,
    public attributes: EmailAttributes,
    public type: EmailType,
  ) {}

  get specialAttributes(): string[] {
    if (this.type.value === EMAIL_ROOT_TEMPLATE_TYPE) {
      return ["innerTemplate"];
    }

    return [];
  }

  get displayAttributes(): string[] {
    return [
      ...this.getDataKeys(this.attributes.metadata || {}),
      ...this.getDataKeys(this.attributes.dataDto || {}),
      ...this.specialAttributes,
    ]
      .sort((a, b) => a.localeCompare(b));
  }

  getDataKeys(data: { [key: string]: any }): string[] {
    return Object.entries(data).map(([key, value]) => (typeof value === "object" ? this.getDataKeys(value) : key)).flat();
  }

  clone(): EmailTemplate {
    return new EmailTemplate(this.body, this.subject, this.attributes, this.type);
  }

  cloneWithUpdatedData(): EmailTemplate {
    return new EmailTemplate(this.updatedBody || this.body, this.updatedSubject || this.subject, this.attributes, this.type);
  }

  static fromDto(dto: EmailTemplateDto, type: EmailType): EmailTemplate {
    return new EmailTemplate(dto.body, dto.subject, dto.attributes, type);
  }

  toDto(): EmailToDto {
    return {
      body: this.body,
      subject: this.subject,
      type: this.type.value,
    };
  }
}
