import Vue from "vue";
import moment from "moment";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, dateTimeMomentFormat, logAndExtractMessage } from "../utils/utils";
import { Team } from "../model/team.model";
import { TeamStats } from "../model/team-stats.model";

const API_PATH = "/teams";

export default {

  namespaced: true,
  state: {
    currentPageLoading: false,
    updating: false,
    currentPage: {
      teams: [],
      hostId: "",
      index: 0,
      size: 25,
    },
    displayTeams: [],
    totalCount: 0,
    teamStats: undefined,
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageTeams(state, teamsDto) {
      state.currentPage.teams = [...teamsDto];
      state.displayTeams = state.currentPage.teams.map((team) => Team.fromDto(team));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    updateTeamStats(state, stats) {
      state.teamStats = stats;
    },
  },
  actions: {

    reloadPage({ state, dispatch }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          hostId: currentPage.hostId,
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount", currentPage.hostId);

        const teamDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageTeams", teamDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }, hostId) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          hostId,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadTeamStats({ commit }, teamId) {
      const body = await bodyWithAuthHeader();

      try {
        const result = await API.get("core", `${API_PATH}/${teamId}/stats`, body);
        commit("updateTeamStats", TeamStats.fromDto(result));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadTeamById(context, teamId) {
      const body = await bodyWithAuthHeader();

      try {
        const result = await API.get("core", `${API_PATH}/${teamId}`, body);
        return Team.fromDto(result);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addOrSaveTeam({ commit, dispatch, state }, team) {
      const body = { ...await bodyWithAuthHeader(), body: team };

      try {
        const result = await (team.id ? API.put("core", `${API_PATH}/${team.id}`, body) : API.post("core", API_PATH, body));
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");
        Vue.toasted.success(
          `${result.name} successfully ${
            team.id ? "updated" : "added"
          }!`,
          {
            duration: 5000,
          },
        );
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteTeam({ commit, dispatch, state }, team) {
      state.updating = true;
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${team.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(`${team.name} successfully deleted!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        state.updating = false;
      }
    },

    async deactivateTeam(context, team) {
      const body = await bodyWithAuthHeader();

      try {
        await API.put("core", `${API_PATH}/${team.id}/deactivate`, body);
        Vue.toasted.success(`${team.name} successfully deactivated!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async activateTeam(context, team) {
      const body = await bodyWithAuthHeader();

      try {
        await API.put("core", `${API_PATH}/${team.id}/activate`, body);
        Vue.toasted.success(`${team.name} successfully activated!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async holdTeam(context, { team, minutes, seconds }) {
      try {
        const duration = moment.duration(`0:${minutes}:${seconds}`);
        await API.post("core", `${API_PATH}/${team.id}/hold`, {
          ...(await bodyWithAuthHeader()),
          queryStringParameters: {
            timeoutSeconds: duration.asSeconds(),
          },
        });

        const expiresAt = moment().add(minutes, "minutes").add(seconds, "seconds").format(dateTimeMomentFormat);
        Vue.toasted.success(`Team "${team.name}" successfully put on hold until ${expiresAt}`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
