export const LAST_ACTIVE_HOST_KEY = "last.active.host.local.storage.key";

export const USER_HOST_GUEST_ROLE = {
  text: "Guest",
  value: "GUEST",
};

export const USER_HOST_ADMIN_ROLE = {
  text: "Admin",
  value: "ADMIN",
};

export const USER_HOST_STANDARD_ROLE = {
  text: "Standard",
  value: "STANDARD",
};

export const USER_HOST_ACCOUNTANT_ROLE = {
  text: "Accountant",
  value: "ACCOUNTANT",
};

export const USER_HOST_ROLES = [
  USER_HOST_GUEST_ROLE,
  USER_HOST_ADMIN_ROLE,
  USER_HOST_STANDARD_ROLE,
  USER_HOST_ACCOUNTANT_ROLE,
];
