import { Auth } from "aws-amplify";
import {
  SUPER_ADMIN_USER_TYPE,
  SUPER_ADMIN_DISPLAY_USER_TYPE, STANDARD_USER_TYPE, STANDARD_DISPLAY_USER_TYPE,
} from "@/utils/utils";
import { HostMembership } from "@/model/host-membership.model";
import { USER_HOST_ADMIN_ROLE } from "@/utils/user-utils";
import { Host } from "@/model/host.model";
import { UserDto } from "@/model/types";

export class User {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public type: string,
    public hostMemberships: HostMembership[],
    public createdAt: string,
    public updatedAt: string,
    public deletedAt: string | undefined,
    public activeHostId: string | undefined,
    public viewerUsersCount: number,
  ) {
  }

  get inactive(): boolean {
    return !!this.deletedAt;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get activeHostMembership(): HostMembership | undefined {
    return this.activeHostId ? this.hostMemberships.find(it => it.host.id === this.activeHostId) : undefined;
  }

  static fromDto(userDto?: UserDto, activeHostId = undefined): User {
    const hostMemberships = userDto?.hostMemberships?.map(HostMembership.fromDto) || [];

    return new User(
        userDto?.id || "",
        userDto?.firstName || "",
        userDto?.lastName || "",
        userDto?.email || "",
        userDto?.userType || "",
        hostMemberships,
        userDto?.createdAt || "",
        userDto?.updatedAt || "",
        userDto?.deletedAt,
        (activeHostId && hostMemberships.find(it => it.host.id === activeHostId)?.host.id)
          || hostMemberships[0]?.host.id,
        userDto?.viewerUsersCount || 0,
    );
  }

  isUserAdminHost(host: Host): boolean {
    return this.hostMemberships.some((it) => (
      it.host.id === host.id && it.roles.some(role => role == USER_HOST_ADMIN_ROLE)
    ));
  }

  get isSuperAdmin(): boolean {
    return this.type === SUPER_ADMIN_USER_TYPE;
  }

  get isStandard(): boolean {
    return this.type === STANDARD_USER_TYPE;
  }

  getUserTypeDisplayName(): string | undefined {
    switch (this.type) {
      case SUPER_ADMIN_USER_TYPE:
        return SUPER_ADMIN_DISPLAY_USER_TYPE;
      case STANDARD_USER_TYPE:
        return STANDARD_DISPLAY_USER_TYPE;
      default:
        return undefined;
    }
  }

  static async getToken(): Promise<string> {
    return (await Auth.currentSession()).getIdToken()
      .getJwtToken();
  }
}
