import { keyBy } from "lodash/index";
import { Host } from "@/model/host.model";
import { HostMembershipDto, HostRole } from "@/model/types";
import { USER_HOST_ROLES } from "@/utils/user-utils";

export class HostMembership {
  constructor(
    public id: string,
    public roles: HostRole[],
    public host: Host,
  ) { }

  static fromDto(dto: HostMembershipDto): HostMembership {
    const roles = keyBy(USER_HOST_ROLES, "value");

    return new HostMembership(
      dto.id,
      dto.roles.map(it => roles[it]),
      Host.fromDto(dto.host),
    );
  }
}
