import {
  REGEX_JOIN_ENTER,
  REGEX_ENTER,
  REGEX_SCIP_SPACES,
  REGEX_SIMPLE_TEXT, REGEX_STYLES, REGEX_STYLES_ENTERS,
} from "@/utils/email-templates-utils";

export class EmailHtmlService {
  static shift = " ".repeat(4);

  static formatThymeleafHtmlEmailTemplateToView(html: string): string {
    if (!this.checkIsHtmlText(html)) {
      return html;
    }

    const htmlRows = html
      .replaceAll(/</g, "\n<")
      .replaceAll(/>/g, ">\n")
      .split(REGEX_ENTER)
      .filter(it => it);

    const shiftedHtmlRows: string[] = [];

    htmlRows.forEach((row, index) => {
      const prevRow = shiftedHtmlRows[index - 1];

      if (!prevRow?.trim()) {
        shiftedHtmlRows.push(row);
        return;
      }

      const trimCurrentRow = row.trim();
      const trimPrevRow = prevRow.trim();
      const prevRowFirstSymbols = trimPrevRow.slice(0, 2);

      if (REGEX_STYLES.test(trimCurrentRow)) {
        shiftedHtmlRows.push(this.formatStyleRow(trimCurrentRow, trimPrevRow.startsWith("<") ? prevRow.split(prevRowFirstSymbols)[0] + this.shift : prevRow.split(prevRowFirstSymbols)[0]));
        return;
      }

      /* DOING:
      *     </div>
      *   </div>
      *  OR
      *     <span />
      *   </div>
      *  OR
      *     simple text
      *  </div>
      * */
      if (
        trimCurrentRow.startsWith("</") && (
          trimPrevRow.startsWith("</")
          || trimPrevRow.endsWith("/>")
          || REGEX_SIMPLE_TEXT.test(prevRowFirstSymbols)
        )
      ) {
        const prevRowShift = prevRow.split(prevRowFirstSymbols)[0];
        shiftedHtmlRows.push(prevRowShift.slice(0, prevRowShift.length - this.shift.length) + trimCurrentRow);
      } else if (
      /* DOING:
      *     <div>
      *       simple text
      *  OR
      *     <div>
      *       <div>
      * */
        (REGEX_SIMPLE_TEXT.test(trimCurrentRow) || trimCurrentRow.startsWith("<"))
          && trimPrevRow.startsWith("<") && !trimPrevRow.startsWith("</") && !trimPrevRow.endsWith("/>") && !trimCurrentRow.startsWith("</")
      ) {
        shiftedHtmlRows.push(prevRow.split(prevRowFirstSymbols)[0] + this.shift + trimCurrentRow);
      } else if (
      /* DOING:
      *     </div>
      *     <div>
      *  OR
      *     </div>
      *     simple text
      *  OR
      *     <div />
      *     <div>
      *  OR
      *     <div />
      *     simple text
      *  OR
      *     simple text
      *     <div>
      *  OR
      *     simple text
      *     simple text
      * */
        ((trimCurrentRow.startsWith("<") || REGEX_SIMPLE_TEXT.test(trimCurrentRow))
          && (trimPrevRow.startsWith("<") || trimPrevRow.endsWith("/>") || REGEX_SIMPLE_TEXT.test(prevRowFirstSymbols)))
      ) {
        shiftedHtmlRows.push(prevRow.split(prevRowFirstSymbols)[0] + trimCurrentRow);
      } else {
        console.error(`Cant convert to html row: ${row}, prevRow: ${prevRow}`);
        shiftedHtmlRows.push(row);
      }
    });

    return shiftedHtmlRows.filter(it => it.trim()).join(REGEX_JOIN_ENTER);
  }

  static formatStyleRow(row: string, prevRowSpaces: string): string {
    const shiftedStyleRows: string[] = [];

    const styleRows = `\n${row}`.replaceAll(REGEX_STYLES_ENTERS, (match: string) => `${match} \n`).split(REGEX_ENTER).filter(it => it.trim());

    styleRows.forEach((styleRow, index) => {
      const prevRow = shiftedStyleRows[index - 1];
      if (!prevRow?.trim()) {
        shiftedStyleRows.push(prevRowSpaces + styleRow);
        return;
      }

      const trimPrevRow = prevRow.trim();
      const prevRowFirstSymbols = trimPrevRow.slice(0, 2);
      const trimCurrentRow = styleRow.trim();

      if (trimCurrentRow.startsWith("}")) {
        const prevRowShift = prevRow.split(prevRowFirstSymbols)[0];
        shiftedStyleRows.push(prevRowShift.slice(0, prevRowShift.length - this.shift.length) + trimCurrentRow);
      } else if ((trimCurrentRow.includes(":") && trimPrevRow.includes(":")) || (trimCurrentRow.endsWith("{") && !trimPrevRow.startsWith("@media"))) {
        shiftedStyleRows.push(prevRow.split(prevRowFirstSymbols)[0] + trimCurrentRow);
      } else if ((trimCurrentRow.includes(":") && !trimPrevRow.includes(":")) || trimPrevRow.startsWith("@media")) {
        shiftedStyleRows.push(prevRow.split(prevRowFirstSymbols)[0] + this.shift + trimCurrentRow);
      } else {
        console.error(`Cant convert to style row: ${row}, prevRow: ${prevRow}`);
        shiftedStyleRows.push(styleRow);
      }
    });

    return shiftedStyleRows.join(REGEX_JOIN_ENTER);
  }

  static removeSpaces(html: string): string {
    return html.replaceAll(REGEX_SCIP_SPACES, "");
  }

  static checkIsHtmlText(text: string): boolean {
    return text.trim().startsWith("<");
  }
}
