<template>
  <CreateHost />
</template>

<script>
export default {
  name: "CreateHostPage",

  routing: {
    routeName: "CreateHostPage",
    path: "/create-host",
    hasAccess: (user) => !!user,
  },

  components: {
    CreateHost: () => import("../components/CreateHost.vue"),
  },
};
</script>

<style scoped>

</style>
