<template>
  <Alerts />
</template>

<script>
export default {
  name: "AlertsPage",

  routing: {
    routeName: "AlertsPage",
    navName: "Alerts",
    icon: "mdi-alert",
    path: "/alerts",
    hasAccess: (user) => user.isSuperAdmin,
  },

  components: {
    Alerts: () => import("../components/alerts/Alerts.vue"),
  },
};
</script>

<style scoped>

</style>
