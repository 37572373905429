<template>
  <TeamRoles />
</template>

<script>
export default {
  name: "TeamRolesPage",

  routing: {
    routeName: "TeamRolesPage",
    navName: "Team Roles",
    icon: "mdi-account-network",
    path: "/team-roles",
    hasAccess: (user) => user.isSuperAdmin,
  },

  components: {
    TeamRoles: () => import("@/components/team-roles/TeamRoles.vue"),
  },
};
</script>
