import Vue from "vue";
import VueRouter from "vue-router";
import { pages } from "@/route-pages";
import { store } from "../main";
import LoginPage from "../views/LoginPage";
import RegistrationPage from "../views/RegistrationPage";
import UserProfilePage from "../views/UserProfilePage";

Vue.use(VueRouter);

const routes = [
  ...pages.map((it) => ({
    name: it.routing.routeName,
    path: it.routing.path,
    components: {
      [it.routing.useNoAuthedUserRouterView ? "noAuthedUser" : "default"]: it,
    },
  })),
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function getTargetPage(route) {
  if (!route.name) {
    console.warn("Unknown route:", route.path);
    return undefined;
  }

  return pages.find((it) => (
    it.routing.routeName === route.name
  ));
}

export const routerBeforeEach = async (to, from, next) => {
  const openSignPageWithHostId = (to.path === LoginPage.routing.path || to.path === RegistrationPage.routing.path) && to.query.hostId;

  if (!store.state.userModule.user && !openSignPageWithHostId) {
    await store.dispatch("userModule/checkLogin");
  }

  if (store.state.userModule.user) {
    const toPage = getTargetPage(to);

    if (toPage && toPage.routing.hasAccess(store.state.userModule.user)) {
      next();
    } else {
      next({ path: UserProfilePage.routing.path });
    }
  } else if (to.path !== LoginPage.routing.path && to.path !== RegistrationPage.routing.path) {
    next({ path: LoginPage.routing.path });
  } else {
    next();
  }
};

router.beforeEach(routerBeforeEach);

export default router;
