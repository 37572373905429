export default {
  name: "DELPHINUS",
  title: "Delphinus ACS",
  colors: {
    primary: "#7860aa",
    secondary: "#b5adc7",
    accent: "#64499c",
    error: "#fa1e1e",
    textColor: "#000",
  },
  iconSrc: "delphinus-logo.ico",
  logoSrc: "delphinus-logo.png",
};
