<template>
  <v-app id="app">
    <AppBar class="app-bar" v-if="showAppBar"></AppBar>
    <v-main>
      <v-container fill-height fluid pa-0 class="align-stretch no-scroll">
        <v-row class="no-gutters fixed-row">
          <v-col
            class="drawer-col"
            :style="styles"
            v-if="showDrawer"
          >
            <NavigationDrawer></NavigationDrawer>
          </v-col>
          <v-col v-if="showSpinner">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col sm="2" class="text-center">
                  <v-progress-circular
                    :size="70"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col :class="{ 'content-col': drawer }" v-if="!showSpinner">
            <router-view v-if="user" />
            <router-view v-if="!user" name="noAuthedUser" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AppBar from "@/components/AppBar.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import router from "@/router";

export default {
  computed: {
    ...mapState("userModule", ["authInProgress", "user"]),
    ...mapGetters("userModule", ["authenticated"]),
    ...mapState("coreModule", ["drawer"]),

    showSpinner() {
      return this.authInProgress
        && router.currentRoute.name != "LoginPage"
        && router.currentRoute.name != "RevertRecoveryInfoPage";
    },

    showAppBar() {
      return this.authenticated
        && !this.showSpinner;
    },

    showDrawer() {
      return this.drawer
        && this.authenticated
        && !this.showSpinner;
    },

    styles() {
      const {
        bar,
        top,
      } = this.$vuetify.application;

      return {
        maxHeight: `calc(100vh - ${top + bar}px) !important`,
        top: `${top + bar}px !important`,
      };
    },
  },
  components: {
    AppBar,
    NavigationDrawer,
  },

  mounted() {
    if (this.$store) {
      this.$store.commit("pagesModule/setViewPages", router.options.routes.map((it) => it.component));
    }
  },
};
</script>

<style lang="scss">
@import "vuetify/src/components/VStepper/_variables.scss";
@import "@/style/constants.scss";

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.v-tooltip__content {
  z-index: 999999 !important;
}

.theme--light.v-tabs-items.tabs-items-background {
  background-color: var(--v-secondary-lighten) !important;
}

.theme--light.v-application {
  background: #efefef !important;

  a {
    color: white;
  }
}

.title-padding {
  .v-toolbar__content {
    padding-left: 24px;
  }
}

.app-bar {
  z-index: 10 !important;
}

.no-scroll {
  overflow-x: hidden;
}

.drawer-col {
  position: -webkit-sticky !important;
  position: fixed !important;
  max-width: 256px;
  z-index: 9 !important;
}

.content-col {
  margin-left: 256px;
}

.toasted {
  font-family: $body-font-family;
}

.drawer-detection-container {
  &.drawer-open {
    max-width: calc(100vw - 256px);
  }

  &.drawer-closed {
    max-width: 100vw;
  }
}

.fixed-row {
  flex-wrap: initial !important;
}

table {
  .router-link-active {
    color: var(--v-textColor-base) !important;
    transition: 0.3s;

    &:hover {
      color: var(--v-primary-base) !important;
    }
  }
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--v-primary-lighten1) !important;

  &:hover {
    background-color: var(--v-primary-lighten2) !important;
  }
}

@media screen and (max-width: 425px) {
  .drawer-col {
    top: 56px;
  }
}

.v-data-table {
  overflow-x: auto;
  max-width: 100%;

  table {
    display: inline-block;
    overflow: auto;
    max-height: calc(100vh - #{$app-bar-height} - #{$toolbar-height} - #{$table-footer-height} - (#{$container-padding} * 2));

    tr:not(.v-data-table__progress) {
      width: 100%;

      td {
        padding: 5px 16px !important;
      }

      th, td {
        max-width: 500px;
        overflow: auto;
        width: 10%;

        &:first-child{
          word-break: break-word;
        }

        &:last-child {
          width: 1%;
          white-space: nowrap;
        }

        &:not(:last-child) {
          min-width: 200px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .v-data-table {
    table {
      th, td {
        width: 100% !important;
      }
    }
  }
}
</style>
