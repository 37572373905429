import { RestAPIClass } from "@aws-amplify/api-rest";
import { Amplify } from "@aws-amplify/core";

export class AcsAPI extends RestAPIClass {
  constructor(options) {
    super(options);
  }

  prepareResponse(response: any): any {
    if (response?.status === "OPERATION_FAILED" || response?.status === "OPERATION_DECLINED") {
      throw Error(response.message);
    }
    return response;
  }

  async get(type: string, path: string, body: any): Promise<any> {
    return this.prepareResponse(await super.get(type, path, body));
  }

  async del(type: string, path: string, body: any): Promise<any> {
    return this.prepareResponse(await super.del(type, path, body));
  }

  async post(type: string, path: string, body: any): Promise<any> {
    return this.prepareResponse(await super.post(type, path, body));
  }

  async put(type: string, path: string, body: any): Promise<any> {
    return this.prepareResponse(await super.put(type, path, body));
  }
}

/*
  node_modules/@aws-amplify/api/src/API.ts

  export const API = new APIClass(null);
  Amplify.register(API);

  APIClass - deprecated, so we use RestAPIClass
*/
export const API = new AcsAPI(null);
Amplify.register(API);
