import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { Host } from "@/model/host.model";
import { HostStats } from "@/model/host-stats.model";
import store from "./index";

const API_PATH = "/hosts";

export default {

  namespaced: true,
  state: {

    currentPageLoading: false,
    displayHosts: [],
    selectedHost: undefined,
    currentPage: {
      hosts: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
    hostStats: undefined,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageHosts(state, hostsDto) {
      state.currentPage.hosts = [...hostsDto];
      state.displayHosts = state.currentPage.hosts.map((host) => Host.fromDto(host));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    updateSelectedHost(state, host) {
      state.selectedHost = host;
    },

    updateHostStats(state, stats) {
      state.hostStats = stats;
    },
  },
  actions: {

    reloadPage({
      dispatch,
      state,
    }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({
      commit,
      dispatch,
    }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount");

        const hostsDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageHosts", hostsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadHostStats({ commit }, hostId) {
      const body = await bodyWithAuthHeader();

      try {
        const result = await API.get("core", `${API_PATH}/${hostId}/stats`, body);
        commit("updateHostStats", HostStats.fromDto(result));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async getHostById({ commit }, hostId) {
      const body = await bodyWithAuthHeader();

      try {
        const result = await API.get("core", `${API_PATH}/${hostId}`, body);
        commit("updateSelectedHost", Host.fromDto(result));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteHost({
      commit,
      dispatch,
      state,
    }, host) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${host.id}`, body);

        if (store.state.userModule.user.hostMemberships.some(it => it.host.id === host.id)) {
          await store.dispatch("userModule/loadUserData");
        }

        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(`${host.name} successfully deleted!`);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addOrSaveHost(context, host) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: host,
      };

      try {
        const result = await (host.id ? API.put("core", `${API_PATH}/${host.id}`, body) : API.post("core", API_PATH, body));
        Vue.toasted.success(
          `${result.name} successfully ${
            host.id ? "updated" : "added"
          }!`,
          {
            duration: 5000,
          },
        );
        return result;
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadFilteredHost(context, { name }) {
      if (!name) return [];

      const body = await bodyWithAuthHeader();

      try {
        const hostsDto = await API.get("core", `${API_PATH}/search?name=${name || ""}`, body);

        return hostsDto.map((host) => Host.fromDto(host));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
