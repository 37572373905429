import { SimpleUser } from "@/model/simple-user.model";

export class HostFilesSize {
  constructor(
    total,
    unit,
  ) {
    this.total = total;
    this.unit = unit;
  }

  static fromDto(dto) {
    return new HostFilesSize(
      dto.total,
      dto.unit,
    );
  }

  get value() {
    return `${this.total} ${this.unit}`;
  }
}

export class HostStats {
  constructor(
    admins,
    lastUpdatedTime,
    totalSizeOfFiles,
  ) {
    this.admins = admins;
    this.lastUpdatedTime = lastUpdatedTime;
    this.totalSizeOfFiles = totalSizeOfFiles;
  }

  static fromDto(dto) {
    return new HostStats(
      dto.admins.map((it) => SimpleUser.fromDto(it)),
      dto.lastUpdatedTime,
      HostFilesSize.fromDto(dto.totalSizeOfFiles),
    );
  }
}
