<template>
  <Login />
</template>

<script>
export default {
  name: "LoginPage",

  routing: {
    routeName: "LoginPage",
    path: "/login",
    hasAccess: (user) => !user,
    useNoAuthedUserRouterView: true,
  },

  components: {
    Login: () => import("../components/login/Login.vue"),
  },
};
</script>
