<template>
  <RevertRecoveryInfo />
</template>

<script>
export default {
  name: "RevertRecoveryInfoPagePage",

  routing: {
    routeName: "RevertRecoveryInfoPagePage",
    path: "/revert-config-change/:id",
    hasAccess: (user) => !!user,
    useNoAuthedUserRouterView: true,
  },

  components: {
    RevertRecoveryInfo: () => import("../components/RevertRecoveryInfo.vue"),
  },
};
</script>
