<template>
  <v-app-bar fixed app v-if="authenticated" color="primary" class="white--text">
    <v-app-bar-nav-icon
      @click.stop="onBurgerClick"
      class="white--text"
    ></v-app-bar-nav-icon>

    <v-avatar color="primary" size="42" class="mr-2">
      <img :src="require(`../assets/${logoSrc}`)" alt="logo" class="logo-img" />
    </v-avatar>

    <v-toolbar-title class="app-title">
      <router-link to="/">{{ appTitle }}</router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu v-model="hostsMenuOpened" offset-y :close-on-content-click="false" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center user-active-host-container"
          v-bind="attrs"
          v-on="on"
        >
          <div class="mr-3 w-100 d-flex flex-column align-end user-info-container">
            <div class="user-name">
              {{ displayUserName }}
            </div>
            <div class="user-position d-flex" v-if="user.activeHostMembership">
              <HostRoles class="mr-2" :host-roles="user.activeHostMembership.roles" /> of {{ user.activeHostMembership.host.name }} {{ user.hostMemberships.length > 1 ? `+${user.hostMemberships.length - 1} more` : "" }}
            </div>
            <div class="user-position" v-else>
              No hosts
            </div>
          </div>
          <v-avatar color="accent" size="42">{{displayUserAbbreviation }}</v-avatar>
          <v-btn icon small class="ml-1">
            <v-icon color="white">mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
      <v-card class="user-hosts-card">
        <v-card-text class="pa-0">
          <v-list class="hosts-list">
            <v-list-item class="create-host" @click="routeToCreateHost">
              <v-icon class="mr-2">mdi-plus-circle</v-icon>
              Create host
            </v-list-item>
            <v-list-item
              v-for="(it) in user.hostMemberships"
              :key="it.id"
              class="host-membership-item d-flex align-center"
              :class="{'selected-host' : isSelectedHost(it.host)}"
              @click="selectHost(it.host)"
            >
              <v-icon
                class="mr-2"
                :color="isSelectedHost(it.host) ? 'primary' : ''"
              >mdi-collage</v-icon>
              {{it.host.name}} <HostRoles :host-roles="it.roles" />
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import theme from "../../app-theme.config";
import { IS_MOBILE } from "@/utils/is-mobile";
import { setLocalStorageData } from "@/utils/utils";
import { LAST_ACTIVE_HOST_KEY } from "@/utils/user-utils";
import HostRoles from "@/components/custom-components/HostRoles";

export default {
  name: "AppBar",
  components: { HostRoles },
  data: () => ({
    IS_MOBILE,
    appTitle: theme.title,
    logoSrc: theme.logoSrc,
    hostsMenuOpened: false,
  }),

  computed: {
    ...mapState("userModule", ["user"]),
    ...mapGetters("userModule", [
      "authenticated",
      "displayUserName",
      "displayUserAbbreviation",
    ]),
  },

  methods: {
    onBurgerClick() {
      this.$store.commit("coreModule/toggleDrawer");
    },

    routeToCreateHost() {
      this.hostsMenuOpened = false;

      if (this.$route.path === "/create-host") return;

      this.$router.push({ path: "/create-host" });
    },

    selectHost(host) {
      this.hostsMenuOpened = false;
      this.user.activeHostId = host.id;
      setLocalStorageData(LAST_ACTIVE_HOST_KEY, host.id);
    },

    isSelectedHost(host) {
      return this.user.activeHostMembership.host.id === host.id;
    },
  },
};
</script>

<style scoped lang="scss">

.hosts-list {
  font-size: 16px;
  line-height: 20px;

  .selected-host {
    color: var(--v-primary-base) !important;
  }
}

.router-link-active {
  display: flex;
  align-items: center;
  text-decoration: none;

  .user-name {
    color: white !important;
  }

  .user-position {
    color: rgb(231, 231, 231) !important;
  }
}

@media (max-width: 500px) {
  .app-title {
    display: none;
  }
  .user-info-container {
    .user-name {
      max-width: 200px;
      overflow: hidden;
      color: white !important;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .user-position {
      max-width: 200px;
      overflow: hidden;
      color: rgb(231, 231, 231) !important;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.logo-img {
  height: 35px;
  width: 35px;
}
</style>
