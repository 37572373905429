import Vue from "vue";
import Toasted from "vue-toasted";
import Amplify from "aws-amplify";
import store from "./store";
// @ts-ignore
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import awsconfig from "./aws-exports";
import installer from "./installer";
import theme from "../app-theme.config";

Amplify.configure({
  ...awsconfig,
  API: {
    endpoints: [
      {
        name: "core",
        endpoint: process.env.VUE_APP_CORE_ENDPOINT || "https://be.dev.acs.altair-web.com",
      },
    ],
  },
});

Vue.config.productionTip = false;
Vue.use(Toasted);

const Application = new Vue({
  // @ts-ignore
  router,
  vuetify,
  store,
  render: (h): any => h(App),
}).$mount("#app");

export { Application, store };

document.addEventListener("DOMContentLoaded", () => {
  const title: any = document.querySelector(".browser-title");
  if (title) {
    title.innerText = theme.title;
  }

  const icon: any = document.querySelector(".browser-ico");
  if (icon) {
    icon.href = `/${theme.iconSrc}`;
  }
});

installer.install();
