import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { TeamMembership } from "@/model/team-membership.model";
import { store } from "@/main";

export default {

  namespaced: true,
  state: {
    lastLoadedPage: "",
    currentPageLoading: false,
    currentPage: {
      teamMemberships: [],
      teamId: "",
      index: 0,
      size: 25,
    },
    displayTeamMemberships: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    clearTeamMemberships(state) {
      state.currentPage.teamMemberships = [];
      state.displayTeamMemberships = [];
    },

    updateCurrentPageTeamMemberships(state, { teamMembershipsDto, entities, filesTypes }) {
      state.currentPage.teamMemberships = [...teamMembershipsDto];
      state.displayTeamMemberships = state.currentPage
        .teamMemberships.map((item) => TeamMembership.fromDto(item, entities, filesTypes));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    reloadPage({ state, dispatch }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      if (!currentPage.teamId) {
        throw Error("Can't find team id");
      }

      commit("startLoadingPage", currentPage);

      try {
        await dispatch("loadTotalCount");

        const body = {
          ...await bodyWithAuthHeader(),
          queryStringParameters: {
            page: currentPage.index,
            pageSize: currentPage.size,
          },
        };

        const teamMembershipsDto = await API.get("core", `/teams/${currentPage.teamId}/memberships`, body);

        if (teamMembershipsDto.length && !store.state.teamRolesModule.entities.length) {
          await store.dispatch("teamRolesModule/loadEntities");
        }

        commit("updateCurrentPageTeamMemberships", {
          teamMembershipsDto,
          entities: store.state.teamRolesModule.entities,
          filesTypes: store.state.teamRolesModule.filesTypes,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ state, commit }) {
      if (!state.currentPage.teamId) {
        throw Error("Can't find team id");
      }

      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `/teams/${state.currentPage.teamId}/memberships/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteMembership({ state, commit, dispatch }, membership) {
      const body = {
        ...await bodyWithAuthHeader(),
      };

      try {
        await API.del("core", `/teams/memberships/${membership.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async assignRole(context, { teamMembershipId, roleId }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          teamRoleId: roleId,
        },
      };

      try {
        await API.put("core", `/teams/memberships/${teamMembershipId}/assign-role`, body);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async revokeRole(context, { teamMembershipId, roleId }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          teamRoleId: roleId,
        },
      };

      try {
        await API.put("core", `/teams/memberships/${teamMembershipId}/revoke-role`, body);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
