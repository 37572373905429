<template>
  <Users />
</template>

<script>
export default {
  name: "UsersPage",

  routing: {
    routeName: "UsersPage",
    navName: "Users",
    icon: "mdi-account-multiple",
    path: "/users",
    hasAccess: (user) => user.isSuperAdmin,
  },

  components: {
    Users: () => import("../components/Users.vue"),
  },
};
</script>
