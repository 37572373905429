import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { User } from "@/model/user.model";

const HOST_MEMBERSHIPS_API_PATH = "/host-memberships";
const USERS_API_PATH = "/users";

export default {

  namespaced: true,
  state: {
    lastLoadedPage: "",
    currentPageLoading: false,
    currentPage: {
      users: [],
      hostId: "",
      index: 0,
      size: 25,
    },
    displayUsersByHost: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageUsers(state, usersDto) {
      state.currentPage.users = [...usersDto];
      state.displayUsersByHost = state.currentPage
        .users.map((dto) => User.fromDto({
          ...dto.user,
          hostMemberships: [{
            id: dto.id,
            host: dto.host,
            roles: dto.roles,
          }],
        }));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    reloadPage({
      state,
      dispatch,
    }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({
      commit,
      dispatch,
    }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          hostId: currentPage.hostId,
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount", currentPage);

        const hostMembershipsDto = await API.get("core", HOST_MEMBERSHIPS_API_PATH, body);
        commit("updateCurrentPageUsers", hostMembershipsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }, currentPage) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          hostId: currentPage.hostId,
        },
      };

      try {
        const count = await API.get("core", `${HOST_MEMBERSHIPS_API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addOrSaveUser({
      commit,
      dispatch,
      state,
    }, user) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          userType: user.type,
        },
      };

      try {
        const result = await (user.id ? API.put("core", `${USERS_API_PATH}/${user.id}`, body) : API.post("core", USERS_API_PATH, body));
        commit("updateTotalCount", state.totalCount + 1);
        dispatch("reloadPage");
        Vue.toasted.success(
          `${result.firstName} ${result.lastName} successfully ${
            user.id ? "updated" : "added"
          }!`,
          {
            duration: 5000,
          },
        );
        return User.fromDto({ ...result, hostMemberships: [] });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async activateUser({ dispatch }, user) {
      const body = await bodyWithAuthHeader();

      try {
        await API.put("core", `${USERS_API_PATH}/${user.id}/activate`, body);
        dispatch("reloadPage");
        Vue.toasted.success(`${user.firstName} ${user.lastName} successfully activated!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteUser({ dispatch }, user) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${USERS_API_PATH}/${user.id}`, body);
        dispatch("reloadPage");
        Vue.toasted.success(`${user.firstName} ${user.lastName} successfully deactivated!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addHostMembership(context, { hostMembership, user }) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          hostId: hostMembership.host.id,
          roles: hostMembership.roles.map(it => it.value),
          userId: user.id,
        },
      };

      try {
        await API.post("core", HOST_MEMBERSHIPS_API_PATH, body);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async updateHostMembership(context, { hostMembership }) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          roles: hostMembership.roles.map(it => it.value),
        },
      };

      try {
        await API.put("core", `${HOST_MEMBERSHIPS_API_PATH}/${hostMembership.id}`, body);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteHostMembership(context, { hostMembership }) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${HOST_MEMBERSHIPS_API_PATH}/${hostMembership.id}`, body);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
