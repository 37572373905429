import { Auth } from "aws-amplify";
import moment from "moment";
import { API } from "@/acs-api";
import { User } from "@/model/user.model";
import {
  bodyWithAuthHeader,
  logAndExtractMessage, getUsername, getSpaceId, setLocalStorageData, getLocalStorageData,
} from "@/utils/utils";
import router from "../router";
import { store } from "@/main";
import { LAST_REGISTERED_EMAIL_KEY } from "@/utils/registration-utils";
import { LAST_ACTIVE_HOST_KEY } from "@/utils/user-utils";

export default {

  namespaced: true,
  state: {
    authInProgress: false,
    user: undefined,
    actionInProcess: false,
    accessToken: undefined,
  },
  mutations: {

    startAuth(state) {
      state.authInProgress = true;
    },

    endAuth(state) {
      state.authInProgress = false;
    },

    setActiveHostId(state, activeHostId) {
      state.user.activeHostId = activeHostId;
      setLocalStorageData(LAST_ACTIVE_HOST_KEY, activeHostId);
    },

    setAccessToken(state, newToken) {
      state.accessToken = newToken.getJwtToken();

      setTimeout(async () => {
        try {
          const rUser = await Auth.currentAuthenticatedUser();
          const rSession = await Auth.currentSession();

          rUser.refreshSession(rSession.refreshToken, (err, session) => {
            this.commit("userModule/setAccessToken", session.getIdToken());
          });
        } catch (e) {
          throw Error(logAndExtractMessage(e));
        }
      }, (newToken.payload.exp - moment()
        .unix() - 60) * 1000);
    },

    updateUser(state, { userDto, lastActiveHostId }) {
      state.user = User.fromDto(userDto, lastActiveHostId);
    },

    clearUser(state) {
      state.user = undefined;
    },
  },
  actions: {
    async checkLogin({
      commit,
      dispatch,
    }) {
      commit("startAuth");

      try {
        const userCognito = await Auth.currentUserInfo();

        if (userCognito && userCognito.id) {
          commit("setAccessToken", (await Auth.currentSession()).getIdToken());
          await dispatch("loadUserData");
        }
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endAuth");
      }
    },

    async checkOnRedirect({ state }) {
      if ((state.user || {}).id && (router.currentRoute.path === "/login" || router.currentRoute.path === "/registration")) {
        if (!state.user.activeHostMembership) {
          await router.push("/profile").catch(() => {});
        } else {
          await router.push("/active-host").catch(() => {});
        }
      } else if (router.currentRoute.path !== "/login" && router.currentRoute.path !== "/registration") {
        await router.push("/login").catch(() => {});
      }
    },

    async signIn({ commit, dispatch }, { email, password }) {
      if (!email || !password) throw Error("No email or password!");

      commit("startAuth");
      try {
        await Auth.signIn(getUsername(email), password);
        await dispatch("loadUserData");
        await dispatch("checkOnRedirect");
        if (!store.state.coreModule.drawer) {
          store.commit("coreModule/toggleDrawer");
        }
      } catch (e) {
        if (e.message.includes("User is not confirmed")) {
          dispatch("resendSignUp", { email });
          const cachedEmail = getLocalStorageData(LAST_REGISTERED_EMAIL_KEY);
          await router.push({
            path: "/registration",
            query: {
              step: 2,
              email: cachedEmail && cachedEmail !== "undefined" ? cachedEmail : email,
            },
          });
          return;
        }
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endAuth");
      }
    },

    async submitEmailOnForgotPassword(context, { email }) {
      if (!email) throw Error("No email!");

      try {
        await Auth.forgotPassword(getUsername(email));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async confirmNewPassword(context, { email, code, newPassword }) {
      if (!email || !code || !newPassword) throw Error("No email, code or password");

      try {
        await Auth.forgotPasswordSubmit(getUsername(email), code, newPassword);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async signUp({ dispatch }, { email, password }) {
      if (!email || !password) throw Error("No email or password");

      try {
        await Auth.signUp({
          username: getUsername(email),
          password,
          attributes: {
            email,
            "custom:space_id": getSpaceId(),
          },
        });
        setLocalStorageData(LAST_REGISTERED_EMAIL_KEY, this.email);
      } catch (e) {
        if (e.message === "User already exists") {
          await dispatch("resendSignUp", { email });
          return;
        }
        throw Error(logAndExtractMessage(e));
      }
    },

    async resendSignUp(context, { email }) {
      if (!email) {
        throw Error("No email to resend sign up");
      }

      try {
        await Auth.resendSignUp(getUsername(email));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async confirmRegistration(context, { hostId, email, code }) {
      if (!email || !code) throw Error("No email or code!");

      try {
        await Auth.confirmSignUp(getUsername(email), code, {
          clientMetadata: {
            hostId,
          },
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async signOut({ commit }) {
      commit("startAuth");

      try {
        await Auth.signOut();
        commit("clearUser");

        await router.push("/login");
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endAuth");
      }
    },

    async updateCurrentUser({ state, dispatch }, userData) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          firstName: userData.firstName,
          lastName: userData.lastName,
        },
      };

      try {
        await API.put("core", "/profile", body);
        await dispatch("loadUserData");
        return state.user;
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadUserData({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const userDto = await API.get("core", "/profile", body);
        commit("updateUser", { userDto, lastActiveHostId: getLocalStorageData(LAST_ACTIVE_HOST_KEY) });
        await store.dispatch("userInvitationsModule/loadPage", {
          index: 0,
          size: 100,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async revertRecoveryInfo(context, id) {
      try {
        await API.del("core", `/profile/recovery-info/${id}/deactivate`);
        return {
          text: "Config change has been reverted!",
          color: "green--text",
        };
      } catch (e) {
        return {
          text: "Failed to revert config change!",
          color: "red--text",
        };
      }
    },
  },
  getters: {
    authenticated: (state) => state.user,
    displayUserName: (state) => (state.user ? `${state.user.firstName} ${state.user.lastName}` : "Loading..."),
    displayUserAbbreviation: (state) => (state.user ? state.user.firstName[0] + state.user.lastName[0] : "UU"),
    accessToken: (state) => state.accessToken,
  },
};
