import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { EmailTemplate } from "@/model/email/email-template.model";
import { EmailRepresent } from "@/model/email/email-represent.model";

const API_PATH = "/emails";

export default {
  namespaced: true,

  state: {
    emailTypes: [],
    emailData: null,
    emailTemplate: null,
    emailRepresent: null,
    loading: false,
    representLoading: false,
    emailRepresentError: null,
  },
  mutations: {
    startLoadingPage(state) {
      state.loading = true;
    },

    endLoadingPage(state) {
      state.loading = false;
    },

    startRepresentLoading(state) {
      state.representLoading = true;
    },

    endRepresentLoading(state) {
      state.representLoading = false;
    },

    updateEmailTypes(state, typesDto) {
      state.emailTypes = typesDto.map(type => {
        const parsedType = type.split("_").join(" ").toLowerCase();
        return {
          text: parsedType.charAt(0).toUpperCase() + parsedType.slice(1),
          value: type,
        };
      }).sort((a, b) => a.text.localeCompare(b.text));
    },

    updateEmailTemplate(state, data) {
      state.emailTemplate = data ? EmailTemplate.fromDto(data.emailTemplateDto, data.type) : undefined;
    },

    updateEmailRepresent(state, representDto) {
      state.emailRepresent = representDto ? EmailRepresent.fromDto(representDto) : undefined;
    },

    updateEmailRepresentError(state, error) {
      state.emailRepresentError = error;
    },
  },
  actions: {
    async loadEmailTypes({ commit }) {
      commit("startLoadingPage");

      try {
        const body = {
          ...await bodyWithAuthHeader(),
        };

        const typesDto = await API.get("core", `${API_PATH}/types`, body);
        commit("updateEmailTypes", typesDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadEmail({ commit }, type) {
      commit("startLoadingPage");

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          type: type.value,
        },
      };

      try {
        const emailTemplateDto = await API.get("core", `${API_PATH}`, body);
        commit("updateEmailTemplate", { emailTemplateDto, type });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async representEmail({ commit }, emailTemplate) {
      commit("startRepresentLoading");

      try {
        const body = {
          ...await bodyWithAuthHeader(),
          body: emailTemplate.toDto(),
        };

        const representDto = await API.post("core", `${API_PATH}/represent`, body);
        commit("updateEmailRepresent", {
          ...representDto,
          type: emailTemplate.type,
        });
        commit("updateEmailRepresentError", null);
      } catch (e) {
        commit("updateEmailRepresentError", e.response?.data?.message || e.message);
      }

      commit("endRepresentLoading");
    },

    async saveEmailTemplate({ commit }, emailTemplate) {
      commit("startLoadingPage");

      const data = emailTemplate.toDto();

      try {
        const body = {
          ...await bodyWithAuthHeader(),
          queryStringParameters: {
            type: data.type,
          },
          body: {
            body: data.body,
            subject: data.subject,
          },
        };

        const dto = await API.put("core", API_PATH, body);

        commit("updateEmailTemplate", {
          emailTemplateDto: {
            attributes: emailTemplate.attributes,
            body: dto.body,
            subject: dto.subject,
          },
          type: emailTemplate.type,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },
  },
};
