
import {
  Component, Prop, Vue,
} from "vue-property-decorator";
import { HostRole } from "@/model/types";
import {
  USER_HOST_ACCOUNTANT_ROLE, USER_HOST_ADMIN_ROLE, USER_HOST_STANDARD_ROLE,
} from "@/utils/user-utils";

@Component({})
export default class HostRoles extends Vue {
  @Prop()
  hostRoles!: HostRole[]

  getChipColor(it: HostRole) {
    if (it === USER_HOST_ADMIN_ROLE) {
      return ["purple", "white--text"];
    }

    if (it === USER_HOST_ACCOUNTANT_ROLE) {
      return ["green", "white--text"];
    }

    if (it === USER_HOST_STANDARD_ROLE) {
      return ["cyan", "white--text"];
    }

    return "default";
  }
}
