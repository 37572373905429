export default {
  name: "AQUILA",
  title: "Aquila ACS",
  colors: {
    primary: "#c94242",
    secondary: "#b0bec5",
    accent: "#8D040E",
    error: "#fa1e1e",
    textColor: "#000",
  },
  iconSrc: "aquila-logo.ico",
  logoSrc: "aquila-logo.png",
};
