/* eslint-disable */

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:8a7f4e96-5670-47fd-98ec-3838549ca98d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_jtxxOXIOK",
    "aws_user_pools_web_client_id": "37kek4pva5n4l4e7ervfhj4ao2",
    "oauth": {
        "domain": "softwea-username-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
            "custom:space_id"
        ],
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
