import { EmailRepresentData, EmailType } from "@/model/email/types";

export class EmailRepresent {
  constructor(
    public body: string,
    public subject: string,
    public type: EmailType,
  ) {
  }

  static fromDto(data: EmailRepresentData): EmailRepresent {
    return new EmailRepresent(data.body, data.subject, data.type);
  }
}
