<template>
  <HostInfo :pMainRoute="`/hosts/${$route.params.id}`" />
</template>

<script>
export default {
  name: "HostInfoPage",

  routing: {
    routeName: "HostInfoPage",
    path: "/hosts/:id",
    hasAccess: (user) => !!user,
  },

  components: {
    HostInfo: () => import("@/components/host-page/HostInfo.vue"),
  },
};
</script>
