<template>
  <v-card width="256px" :style="styles" class="rounded-0 drawer-card">
    <v-navigation-drawer permanent fill-height>
      <v-list dense nav class="drawer-list">
        <div v-for="(item, i) in navItems" :key="item.title">
          <router-link
            :to="item.path"
            :active-class="activateRouteClass(item.path) ? 'active-menu-item' : ''"
          >
            <v-list-item link @click="onMenuClick">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-badge
                :content="item.badge ? item.badge.getValue() : 0"
                :value="item.badge ? item.badge.getValue() : 0"
                :color="item.badge ? item.badge.color : ''"
                bordered
                overlap
                offset-x="-10"
                offset-y="26"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.navName }}</v-list-item-title>
                </v-list-item-content>
              </v-badge>
            </v-list-item>
          </router-link>
          <v-divider class="my-2" v-if="checkIsSeparatedItem(item, i)"></v-divider>
        </div>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            @click="onLogoutClick"
            color="primary"
            text
            elevation="3"
            :disabled="authInProgress"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import ActiveHostPage from "../views/ActiveHostPage";
import UserProfilePage from "../views/UserProfilePage";
import HostsPage from "../views/HostsPage";
import UsersPage from "../views/UsersPage";
import AlertsPage from "../views/AlertsPage";
import EmailTemplatesPage from "@/views/EmailTemplatesPage";
import UserInvitationsPage from "@/views/UserInvitationsPage";
import TeamRolesPage from "@/views/TeamRolesPage";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "NavigationDrawer",

  data: () => ({
    drawer: true,
    localStorageName: "treeviewDrawer",
  }),

  methods: {
    onMenuClick() {
      this.$store.commit("coreModule/drawerLinkClicked");
    },

    async onLogoutClick() {
      try {
        await this.$store.dispatch("userModule/signOut");
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    activateRouteClass(route) {
      return (
        route == this.$route.path
        || route.split("/")[1] == this.$route.path.split("/")[1]
      );
    },

    checkIsSeparatedItem(routing, ind) {
      return this.separatedItems.some(it => it.routeName === routing.routeName)
          && ind !== this.navItems.length - 1;
    },
  },

  computed: {
    ...mapState("userModule", ["authInProgress", "user"]),

    navItems() {
      return [
        ActiveHostPage,
        UserProfilePage,
        UserInvitationsPage,
        HostsPage,
        UsersPage,
        TeamRolesPage,
        AlertsPage,
        EmailTemplatesPage,
      ].map((it) => it.routing)
        .filter((it) => it && it.hasAccess(this.user));
    },

    separatedItems() {
      return [
        ActiveHostPage,
        UserInvitationsPage,
      ].map((it) => it.routing);
    },

    styles() {
      const {
        bar,
        top,
      } = this.$vuetify.application;

      return {
        height: `calc(100vh - ${top + bar}px) !important`,
      };
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>

<style lang="scss">
.drawer-list {
  .active-menu-item {
    .v-list-item {
      background-color: var(--v-primary-lighten3) !important;
    }
  }

  .not-active-item {
    opacity: 0.5;
  }

  .disabled-item {
    position: relative;
    left: -24px;
    opacity: 0.5;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-treeview-node__label {
    overflow: visible;
  }

  .v-treeview-node__level {
    width: 12px !important;
  }

  .v-list-item__icon {
    margin-right: 10px !important;
  }

  .v-expansion-panels {
    .v-expansion-panel--active {
      margin-top: 0;
    }

    .v-expansion-panel--active + .v-expansion-panel {
      margin-top: 0;
    }
  }
}
</style>
