import {
  DISPLAY_HOST_STATUS_ACTIVE, DISPLAY_HOST_STATUS_DRAFT, DISPLAY_HOST_STATUS_INACTIVE,
  HOST_STATUS_ACTIVE, HOST_STATUS_DRAFT, HOST_STATUS_INACTIVE,
} from "@/utils/utils";

export class Host {
  id;

  name;

  accountId;

  inactive;

  status;

  primaryAddress;

  secondaryAddress;

  contactName;

  contactEmail;

  contactPhone;

  invitationInfo;

  city;

  zip;

  country;

  state;

  createdAt;

  updatedAt;

  deletedAt;

  static fromDto(hostDto) {
    const host = new Host();
    host.id = hostDto ? hostDto.id : "";
    host.name = hostDto ? hostDto.name : "";
    host.accountId = hostDto ? hostDto.accountId : "";
    host.status = hostDto ? hostDto.status : "";
    host.primaryAddress = hostDto ? hostDto.primaryAddress : "";
    host.secondaryAddress = hostDto ? hostDto.secondaryAddress : "";
    host.contactName = hostDto ? hostDto.contactName : "";
    host.contactEmail = hostDto ? hostDto.contactEmail : "";
    host.contactPhone = hostDto ? hostDto.contactPhone : "";
    host.invitationInfo = hostDto ? hostDto.invitationInfo : "";
    host.city = hostDto ? hostDto.city : "";
    host.zip = hostDto ? hostDto.zip : "";
    host.country = hostDto ? hostDto.country : "";
    host.state = hostDto ? hostDto.state : "";
    host.createdAt = hostDto ? hostDto.createdAt : "";
    host.updatedAt = hostDto ? hostDto.updatedAt : "";
    host.deletedAt = hostDto ? hostDto.deletedAt : "";
    host.inactive = !!host.deletedAt;

    return host;
  }

  getHostDisplayStatus() {
    switch (this.status) {
      case HOST_STATUS_ACTIVE:
        return DISPLAY_HOST_STATUS_ACTIVE;
      case HOST_STATUS_DRAFT:
        return DISPLAY_HOST_STATUS_DRAFT;
      case HOST_STATUS_INACTIVE:
        return DISPLAY_HOST_STATUS_INACTIVE;
      default:
        return undefined;
    }
  }
}
