<template>
  <TeamInfo />
</template>

<script>
export default {
  name: "TeamInfoPage",

  routing: {
    routeName: "TeamInfoPage",
    path: "/teams/:id",
    hasAccess: (user) => !!user,
  },

  components: {
    TeamInfo: () => import("../components/team-page/TeamInfo.vue"),
  },
};
</script>
