<template>
  <HostInfo pMainRoute="/active-host" />
</template>

<script>
export default {
  name: "ActiveHostPage",

  routing: {
    routeName: "ActiveHostPage",
    navName: "Active Host",
    icon: "mdi-collage",
    path: "/active-host",
    hasAccess: (user) => !!user.activeHostMembership,
  },

  components: {
    HostInfo: () => import("@/components/host-page/HostInfo.vue"),
  },
};
</script>

<style scoped>

</style>
