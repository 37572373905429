<template>
  <Hosts />
</template>

<script>
export default {
  name: "HostsPage",

  routing: {
    routeName: "HostsPage",
    navName: "Hosts",
    icon: "mdi-sitemap",
    path: "/hosts",
    hasAccess: (user) => user.isSuperAdmin,
  },

  components: {
    Hosts: () => import("@/components/Hosts.vue"),
  },
};
</script>
