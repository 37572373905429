
import Vue from "vue";
import {
  Component, Emit, Prop, Ref, Watch,
} from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { mixin as clickAway } from "vue-clickaway";
import { VueClass } from "vue-class-component/lib/declarations";
import { State } from "vuex-class";
import lodash from "lodash";
import {
  EMAIL_TEMPLATE_SMALL_TEXTAREA,
  EMAIL_TEMPLATE_BIG_TEXTAREA,
  REGEX_ENTER,
  REGEX_JOIN_ENTER,
  REGEX_CONSTANTS,
  REGEX_EMPTY_CONSTANTS,
  REGEX_ALL_CONSTANTS,
  REGEX_HIGHLIGHTED_TEXT_TAGS,
  REGEX_HTML_TAGS,
  REGEX_ENTER_G,
  REGEX_BEFORE_AFTER_WORDS_SPACES,
  REGEX_CHECK_HAS_EXPRESSION,
  REGEX_PARSED_CONSTANT_EXPRESSION, REGEX_HTML_TAG_START, REGEX_HTML_TAG_END,
} from "@/utils/email-templates-utils";
import { EmailTemplate } from "@/model/email/email-template.model";
import { EmailHtmlService } from "@/services/email/email-html.service";
import { getCoreModuleNamespace, getEmailTemplatesModuleNamespace } from "@/store/store.utils";

type Mode = {
  type: string;
  rows: number;
  rowHeight: number;
  top: number;
  componentClass: string;
  insideComponent: string;
  updRowsWithParentComponentHeight?: (height: number) => void;
}

const TEXTAREA_DEFAULT: Mode = {
  type: EMAIL_TEMPLATE_BIG_TEXTAREA,
  rows: 10,
  rowHeight: 28,
  top: 12,
  componentClass: "textarea-container",
  insideComponent: "textarea",
  updRowsWithParentComponentHeight(height) {
    const updRows = Math.ceil(height / this.rowHeight) - 2;
    if (updRows > this.rows) {
      this.rows = updRows;
    }
  },
};

@Component({})
export default class EmailTemplateField extends mixins(clickAway as VueClass<object & Record<never, any>>) {
  TWA_TEXTAREA_MODE: Mode = TEXTAREA_DEFAULT;

  TWA_TEXT_FIELD_MODE: Mode = {
    ...TEXTAREA_DEFAULT,
    type: EMAIL_TEMPLATE_SMALL_TEXTAREA,
    rows: 2,
  };

  TWA_MODES: Mode[] = [
    this.TWA_TEXTAREA_MODE,
    this.TWA_TEXT_FIELD_MODE,
  ];

  text = "";

  assistantActivated = false;

  userInputConstantName = "";

  hoveredConstantIndex = 0;

  autoFocus = false;

  lastTargetRowIndex = 0;

  constantIndexInLine = 0;

  itemHeight = 30;

  @Prop()
  pLabel!: string;

  @Prop()
  pText!: string;

  @Prop()
  pMode!: string;

  @Prop()
  pParentClass!: string | undefined;

  @Prop()
  pUnifyFieldClass!: string;

  @Ref("textInputComponent")
  textInputComponent!: any;

  @Ref("constantsContainer")
  constantsContainer!: any

  @Ref("constants")
  constants!: any[]

  @Ref("highlightsContainer")
  highlightsContainer: any

  @Ref("highlights")
  highlights!: any

  @State("drawer", getCoreModuleNamespace())
  drawer!: boolean;

  @State("emailTemplate", getEmailTemplatesModuleNamespace())
  emailTemplate!: EmailTemplate | null;

  @State("loading", getEmailTemplatesModuleNamespace())
  loading!: boolean;

  @Watch("pText")
  onUpdPText(): void {
    this.text = this.pText;
    this.highlightBackground();
    this.emitValidationCheck();
  }

  @Watch("drawer")
  onUpdDrawer(): void {
    this.highlightBackground();
  }

  get currentMode(): Mode {
    return lodash.keyBy(this.TWA_MODES, "type")[this.pMode];
  }

  get isDisplayReformatBtn() {
    return this.emailTemplate && EmailHtmlService.checkIsHtmlText(this.text);
  }

  get assistantStyle() {
    const newTopValue = this.currentMode.rowHeight * ((this.lastTargetRowIndex || 0) + 2);
    const maxTopValue = this.currentMode.rowHeight * (this.currentMode.rows + 2);
    return {
      top: `${newTopValue >= maxTopValue ? maxTopValue : newTopValue}px`,
    };
  }

  get highlightsContainerStyle() {
    return {
      height: `${this.currentMode.rows * this.currentMode.rowHeight}px`,
      top: `${this.currentMode.top}px`,
    };
  }

  get attributesByUserInput() {
    if (!this.emailTemplate) return [];
    return (
      (this.userInputConstantName && this.emailTemplate.displayAttributes.filter((it) => (
        it.toLowerCase().includes(this.userInputConstantName.toLowerCase())
      )))
        || this.emailTemplate.displayAttributes
    );
  }

  get assistantTravelKeys() {
    return [
      {
        name: "up",
        code: 38,
        getNewHoveredConstantIndex: (it: number) => (
          it === 0
            ? this.attributesByUserInput.length - 1
            : it - 1
        ),
      },
      {
        name: "down",
        code: 40,
        getNewHoveredConstantIndex: (it: number) => (
          it === this.attributesByUserInput.length - 1
            ? 0
            : it + 1
        ),
      },
    ];
  }

  get highlightedText() {
    const notCorrectConstants = this.getIncorrectConstantsFromText(this.text);

    const formatHtmlString = (text: string) => text.split("").map(it => `<mark class="html-tag">${it}</mark>`).join("");

    // <br>`s at the end are required to display correctly at the end of the scroll.
    return `${this.text
      .replaceAll(REGEX_HTML_TAGS, (matchedText: string) => {
        const matchedConstants = [...matchedText.matchAll(REGEX_CONSTANTS)];

        if (!matchedConstants.length) {
          const tag = matchedText.replaceAll(REGEX_HTML_TAG_START, formatHtmlString);
          if (matchedText.endsWith("/>")) {
            return tag.slice(0, tag.length - 2) + formatHtmlString("/>");
          }
          if (matchedText.endsWith(">")) {
            return tag.slice(0, tag.length - 1) + formatHtmlString(">");
          }
          return tag;
        }

        const constants = matchedConstants.map((it, index) => {
          const startIndex = it.index! + it[0].length;
          const endIndex = matchedConstants[index + 1]?.index || matchedText.length;
          const textAfterConstant = matchedText.slice(startIndex, endIndex).replaceAll(REGEX_HTML_TAG_END, formatHtmlString);
          return {
            constant: it[0],
            index: it.index,
            textAfterConstant,
          };
        });

        const rowBeforeConstants = matchedText.slice(0, constants[0].index)
          .replaceAll(REGEX_HTML_TAG_START, formatHtmlString);

        return `${rowBeforeConstants}${constants.map(it => `${it.constant}${it.textAfterConstant}`).join("")}`;
      })
      .replaceAll(REGEX_ENTER_G, "<br>")
      .replaceAll(REGEX_BEFORE_AFTER_WORDS_SPACES, "&nbsp;")
      .replace(REGEX_HIGHLIGHTED_TEXT_TAGS, (matchedText: string) => {
        const constantText = [...matchedText.matchAll(/\w*/g)].find(it => it[0]);
        return `<mark ${
          notCorrectConstants.some((it) => !constantText || it.fullText === (constantText.input))
            ? ""
            : "class=\"correct-constant\""
        }>${matchedText}</mark>`;
      })}<br><br>`;
  }

  @Emit("onUpdText")
  emitUpdatedText() {
    return this.text;
  }

  async onChangeValue(newTextareaValue: string) {
    const {
      lastTargetRowIndex,
      userInputConstantName,
      constantIndexInLine,
    } = this.getTargetConstantParams(this.text, newTextareaValue);
    this.userInputConstantName = userInputConstantName || "";

    if (lastTargetRowIndex !== undefined) {
      this.lastTargetRowIndex = lastTargetRowIndex;
    }
    if (constantIndexInLine !== undefined) {
      this.constantIndexInLine = constantIndexInLine;
    }

    // get textarea cursor before changes
    const textareaCursor = this.textInputComponent.$refs.input.selectionStart;
    // get textarea scroll before changes
    const textareaScrollTop = this.textInputComponent.$refs.input.scrollTop;

    const previousTextAreaValue = this.text;
    this.text = newTextareaValue;
    // we need to wait for the rest of the processes to complete,
    // otherwise the cursor does not change and the textarea will not be updated
    await Vue.nextTick();

    this.text = this.getNewTextareaValue(newTextareaValue, previousTextAreaValue);
    // we need to wait for the textarea processes to complete.
    await Vue.nextTick();

    // when we rewrite textarea, the cursor changes
    if (
      this.text !== newTextareaValue
        && this.textInputComponent.$refs.input.selectionStart !== textareaCursor
    ) {
      this.updateInputCursorAndScroll(textareaCursor, textareaScrollTop);
    }

    this.assistantActivated = lastTargetRowIndex !== undefined;
    if (this.assistantActivated) {
      // we need to wait for the assistant to open
      await Vue.nextTick();
      this.constantsContainer.scrollTop = 0;
      this.hoveredConstantIndex = 0;
    }

    this.highlightBackground();
    this.emitUpdates();
  }

  async updateHtmlFormatting() {
    const textareaCursor = this.textInputComponent.$refs.input.selectionStart;
    const textareaScrollTop = this.textInputComponent.$refs.input.scrollTop;
    this.text = EmailHtmlService.formatThymeleafHtmlEmailTemplateToView(EmailHtmlService.removeSpaces(this.text));
    await Vue.nextTick();
    this.updateInputCursorAndScroll(textareaCursor, textareaScrollTop);
    this.highlightBackground();
    this.emitUpdates();
  }

  async onEnterConstant(event: any, selectedConstant: string) {
    if (!this.assistantActivated) return;

    event.preventDefault();

    this.onSelectConstant(selectedConstant);
  }

  onSelectConstant(selectedConstant: string) {
    if (this.lastTargetRowIndex === undefined) return;

    const rows = this.text.split(REGEX_ENTER);
    const targetRow = rows[this.lastTargetRowIndex];

    // replace target constant in target row from text
    rows[this.lastTargetRowIndex] = targetRow.replace(REGEX_CONSTANTS, (match1: string, match2: string, text: string, offset: number, fullString: string) => {
      const matchedValue = (match1.split("${")[1] || match1.split("${")[0]).replaceAll("}", "");
      // if do not check offset !== this.constantIndexInLine
      // ${}  ${hi -> press to select from card -> ${hi}  ${hi}
      // and was matched all!
      if (
        matchedValue !== this.userInputConstantName
          || offset !== this.constantIndexInLine
      ) {
        return match1;
      }

      return fullString.slice(offset, fullString.length)[match1.length] === "}"
        ? `\${${selectedConstant} `
        : `\${${selectedConstant}} `;
    });

    const textareaCursor = this.textInputComponent.$refs.input.selectionStart;
    const textareaScrollTop = this.textInputComponent.$refs.input.scrollTop;

    this.text = rows.join(REGEX_JOIN_ENTER);
    this.updateInputCursorAndScroll(textareaCursor + selectedConstant.length + 1, textareaScrollTop);
    this.assistantActivated = false;
    this.highlightBackground();
    this.emitUpdates();
  }

  getTargetConstantParams(oldTextareaValue: string, newTextareaValue: string) {
    const targetConstant = this.getUserModifiedConstant(oldTextareaValue, newTextareaValue);

    if (!targetConstant) {
      return {
        lastTargetRowIndex: undefined,
        constantIndexInLine: undefined,
        userInputConstantName: undefined,
      };
    }

    const {
      targetRowIndex,
      constantIndexInLine,
    } = this.getTargetConstantIndexes(targetConstant, newTextareaValue);
    return {
      lastTargetRowIndex: targetRowIndex,
      constantIndexInLine,
      userInputConstantName: targetConstant[1],
    };
  }

  getUserModifiedConstant(oldTextareaValue: string, newTextareaValue: string) {
    const newTextareaValueConstants = [...newTextareaValue.matchAll(REGEX_CONSTANTS)];
    const oldTextareaValueConstants = [...oldTextareaValue.matchAll(REGEX_CONSTANTS)];

    return newTextareaValueConstants.find((newValue, index) => {
      const oldValue = oldTextareaValueConstants[index];
      if (!oldValue) {
        return true;
      }

      const valueWithMinLength = lodash.minBy([newValue[1], oldValue[1]], (it) => it.length) as string;
      const valueWithMaxLength = lodash.maxBy([oldValue[1], newValue[1]], (it) => it.length) as string;

      return valueWithMinLength !== valueWithMaxLength
        ? valueWithMaxLength.startsWith(valueWithMinLength)
        : false;
    });
  }

  getNewTextareaValue(textareaValue: string, previousTextAreaValue: string) {
    const rows = textareaValue.split(REGEX_ENTER);
    const lastTargetRowIndex = this.lastTargetRowIndex || 0;
    const targetRow = rows[lastTargetRowIndex];
    // targetRow may be undefined since the user can delete the last target row
    if (!targetRow) {
      this.lastTargetRowIndex = rows.length - 1;
      return textareaValue;
    }

    return this.getChangedTextareaValue(
      rows,
      targetRow,
      previousTextAreaValue.split(REGEX_ENTER)[lastTargetRowIndex],
      lastTargetRowIndex,
    );
  }

  getChangedTextareaValue(rows: string[], targetRow: string, targetRowInPreviousTextareaValue: string, lastTargetRowIndex: number) {
    const failedConstants = this.getFailedMatchesWithErrors(targetRow);

    if (failedConstants.length) {
      rows[lastTargetRowIndex] = targetRow.replace(REGEX_ALL_CONSTANTS, (match: string, p1: string, p2: string, p3: string, str: string, offset: number) => {
        const failedConstant = failedConstants.find((it) => (
          (it?.constant && match === it?.constant[0]
                && offset === it?.constant.index)
        ));

        if (!failedConstant) return match;

        if (failedConstant.emptyConstant) {
          const targetStr = failedConstant.emptyConstant[0];
          return targetStr[targetStr.length - 1] === "}"
            ? targetStr
            : `${targetStr}}`;
        }

        return failedConstant.constant[0];
      });
    }

    // detect Enter at the end of constant
    // ${asd
    // }
    const rowUnderTargetRow = rows[lastTargetRowIndex + 1];
    if (rowUnderTargetRow && rowUnderTargetRow.startsWith("}")) {
      rows[lastTargetRowIndex] += rowUnderTargetRow;
      rows.splice(lastTargetRowIndex + 1, 1);
    }

    return rows.join(REGEX_JOIN_ENTER);
  }

  getFailedMatchesWithErrors(targetRow: string) {
    const constants = [...targetRow.matchAll(REGEX_CONSTANTS)];
    const emptyConstants = [...targetRow.matchAll(REGEX_EMPTY_CONSTANTS)];

    return constants.map((constant) => {
      const emptyConstant = emptyConstants.find((it) => (
        it[0].startsWith(constant[0])
          && it.index === constant.index
      ));

      if (!emptyConstant) {
        return undefined;
      }

      return {
        constant,
        emptyConstant,
      };
    })
      .filter((it) => it);
  }

  getTargetConstantIndexes(targetConstant: RegExpMatchArray, newTextareaValue: string) {
    const rowWithConstantStart = newTextareaValue.slice(0, targetConstant.index)
      .split(REGEX_ENTER)
      .at(-1);

    const rowWithConstant = rowWithConstantStart + (newTextareaValue.slice(targetConstant.index, newTextareaValue.length)
      .split(REGEX_ENTER).at(0) || "");

    const rows = newTextareaValue.split(REGEX_ENTER);

    const targetRowIndex = rows.findIndex((it) => it === rowWithConstant);

    const rowsBeforeRowWithConstant = rows.splice(0, targetRowIndex);

    const symbolsCountBeforeRowWithConstant = lodash.sum(rowsBeforeRowWithConstant.map((it) => it.length));

    let constantIndexInLine = (targetConstant.index || symbolsCountBeforeRowWithConstant) - symbolsCountBeforeRowWithConstant;

    // we need to drop count of enters
    if (symbolsCountBeforeRowWithConstant !== 0) {
      constantIndexInLine -= rowsBeforeRowWithConstant.length;
    }

    return {
      targetRowIndex,
      constantIndexInLine,
    };
  }

  onOutsideClick() {
    this.assistantActivated = false;
  }

  highlightBackground() {
    this.highlights.innerHTML = this.highlightedText;
    this.recalcHighlightsContainer();
  }

  getIncorrectConstantsFromText(text: string) {
    const rawMatches = [...text.matchAll(REGEX_CONSTANTS)];

    return rawMatches
      .map((it, constantIndex) => ({
        text: it[1],
        fullText: it[0],
        index: it.index,
        constantIndex,
      }))
      .filter((it) => {
        const hasInList = this.emailTemplate!.displayAttributes.some((attr) => attr === it.text);
        const isExpression = REGEX_CHECK_HAS_EXPRESSION.test(it.fullText);

        if (
          it.text.startsWith("#")
            || it.text.startsWith("initialInviteAt")
            || (!isExpression && hasInList)
        ) return false;

        const failedConstantsFromExpression = isExpression
          ? [...it.fullText.matchAll(REGEX_PARSED_CONSTANT_EXPRESSION)]
            .filter(item => (
              !!item[0]
                && !item[0].startsWith("\"")
                && !this.emailTemplate!.displayAttributes.some((attr) => attr === item[0])
            ))
          : [];

        return (
          failedConstantsFromExpression.length
            || (!isExpression && !hasInList)
            || it.fullText.at(-1) !== "}"
        );
      });
  }

  emitUpdates() {
    this.emitValidationCheck();
    this.emitUpdatedText();
  }

  @Emit("onValidationCheck")
  emitValidationCheck(): boolean {
    return this.getIncorrectConstantsFromText(this.text).length === 0
        && this.getFailedMatchesWithErrors(this.text).length === 0
        && !!this.text;
  }

  recalcHighlightsContainer() {
    const input = this.textInputComponent.$refs.input;
    // 6px - scroll width
    this.highlightsContainer.style.height = `${input.offsetHeight - 6}px`;
    this.highlightsContainer.style.width = `${input.offsetWidth - 6}px`;
    this.highlightsContainer.scrollTop = input.scrollTop;
    this.highlightsContainer.scrollLeft = input.scrollLeft;
  }

  updateInputCursorAndScroll(textareaCursor: number, textareaScrollTop: number) {
    setTimeout(() => {
      const textarea: any = document.querySelector(
        `.${this.pUnifyFieldClass} .${this.currentMode.componentClass} ${this.currentMode.insideComponent}`,
      );
      if (textarea) {
        textarea.setSelectionRange(textareaCursor, textareaCursor);
      }
      this.textInputComponent.$refs.input.scrollTop = textareaScrollTop;
    });
  }

  onFocus() {
    this.autoFocus = true;
  }

  mouseOver(index: number) {
    this.hoveredConstantIndex = index;
    this.itemHeight = this.constants[index].offsetHeight;
  }

  onClickToConstant(fullName: string) {
    this.onSelectConstant(fullName);
    this.textInputComponent.focus();
  }

  onPressArrow(event: any) {
    if (!this.assistantActivated) return;

    const foundEvent = this.assistantTravelKeys.find((it) => it.code === event.keyCode);
    if (foundEvent) {
      event.preventDefault();
      this.hoveredConstantIndex = foundEvent.getNewHoveredConstantIndex(this.hoveredConstantIndex);
      this.constantsContainer.scrollTop = this.hoveredConstantIndex * this.itemHeight;
    }
  }

  mounted() {
    setTimeout(() => {
      if (this.pParentClass && this.currentMode?.updRowsWithParentComponentHeight) {
        const parent: any = document.querySelector(`.${this.pParentClass}`);
        this.currentMode.updRowsWithParentComponentHeight(parent.offsetHeight);
      }
    }, 300);
  }
}
