import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { HostInvitation } from "@/model/host-invitation.model";

export const API_PATH = "/host-invitations";

export default {

  namespaced: true,
  state: {

    currentPageLoading: false,
    displayHostInvitations: [],
    currentPage: {
      hostInvitations: [],
      index: 0,
      size: 25,
      hostId: undefined,
    },
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      if (!currentPage.hostId) {
        throw Error("Host ID does not provided!");
      }

      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageHostInvitations(state, hostInvitationsDto) {
      state.currentPage.hostInvitations = [...hostInvitationsDto];
      state.displayHostInvitations = state.currentPage.hostInvitations.map(HostInvitation.fromDto);
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {
    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          hostId: currentPage.hostId,
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount");

        const hostInvitationsDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageHostInvitations", hostInvitationsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ state, commit }) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          hostId: state.currentPage.hostId,
        },
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addOrSaveHostInvitation({ commit, dispatch, state }, invitation) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          email: invitation.email,
          hostId: invitation.host.id,
          roles: invitation.roles.map(it => it.value),
        },
      };

      try {
        await (invitation.id
          ? API.put("core", `${API_PATH}/${invitation.id}`, body)
          : API.post("core", API_PATH, body));

        if (!invitation.id) {
          commit("updateTotalCount", state.totalCount + 1);
        }

        dispatch("reloadPage");

        Vue.toasted.success(`${invitation.email} successfully ${invitation.id ? "updated" : "added"}!`, {
          duration: 5000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteHostInvitation({ commit, dispatch, state }, invitation) {
      state.currentPageLoading = true;

      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${invitation.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(`${invitation.email} successfully deleted!`, {
          duration: 5000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
      state.currentPageLoading = false;
    },
  },
};
