import { User } from "./user.model";

export class TeamStats {
  constructor(
    description,
    lastCommentUpdateTime,
    lastDocumentUpdateTime,
    manager,
    members,
    name,
    outstandingNominations,
  ) {
    this.description = description;
    this.lastCommentUpdateTime = lastCommentUpdateTime;
    this.lastDocumentUpdateTime = lastDocumentUpdateTime;
    this.manager = manager;
    this.members = members;
    this.name = name;
    this.outstandingNominations = outstandingNominations;
  }

  static fromDto(dto) {
    return new TeamStats(
      dto.description,
      dto.lastCommentUpdateTime,
      dto.lastDocumentUpdateTime,
      dto.manager ? User.fromDto(dto.manager) : undefined,
      dto.members,
      dto.name,
      dto.outstandingNominations,
    );
  }
}
