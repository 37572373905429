import { API } from "@/acs-api";
import {
  bodyWithAuthHeader, logAndExtractMessage, NOMINATION_STATE_ACCEPTED, NOMINATION_TYPE_MEMBER,
} from "@/utils/utils";
import { Nomination } from "@/model/nomination.model";

const getApiPath = (id) => `/teams/${id}/nominations`;

const TARGET_QUERY_PARAMS = {
  nominationType: NOMINATION_TYPE_MEMBER.value,
  nominationApprovalState: NOMINATION_STATE_ACCEPTED.value,
};

export default {
  namespaced: true,
  state: {
    lastLoadedPage: "",
    acceptedMemberNominationsLoading: false,
    currentPage: {
      nominations: [],
      teamId: "",
      index: 0,
      size: 25,
    },
    acceptedMemberNominations: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.acceptedMemberNominationsLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.acceptedMemberNominationsLoading = false;
    },

    updateCurrentPageNominations(state, nominationsDto) {
      state.currentPage.nominations = [...nominationsDto];
      state.acceptedMemberNominations = state.currentPage.nominations.map((item) => Nomination.fromDto(item));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    reloadPage({
      state,
      dispatch,
    }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({
      commit,
      dispatch,
    }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index || 0,
          pageSize: currentPage.size || 25,
          ...TARGET_QUERY_PARAMS,
        },
      };

      try {
        await dispatch("loadTotalCount", currentPage.teamId);

        const nominationsDto = await API.get("core", getApiPath(currentPage.teamId), body);
        commit("updateCurrentPageNominations", nominationsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }, teamId) {
      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          ...TARGET_QUERY_PARAMS,
        },
      };

      try {
        const count = await API.get("core", `${getApiPath(teamId)}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
