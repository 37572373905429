<template>
  <UserProfile />
</template>

<script>
export default {
  name: "UserProfilePage",

  routing: {
    routeName: "UserProfilePage",
    navName: "My Profile",
    icon: "mdi-account-circle",
    path: "/profile",
    hasAccess: (user) => !!user,
  },

  components: {
    UserProfile: () => import("@/components/user/UserProfile.vue"),
  },
};
</script>
