import lodash from "lodash";
import Vue from "vue";
import getUuid from "uuid-by-string";
import { User } from "@/model/user.model";
import aquilaTheme from "../../theme/aquila-theme";
import delphinusTheme from "../../theme/delphinus-theme";

export const AQUILA_THEME_NAME = aquilaTheme.name;
export const DELPHINUS_THEME_NAME = delphinusTheme.name;

export const dismissAction = {
  action: {
    text: "Dismiss",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

export const EMAIL_REGEX = /^[\w\d~!$%^&*_=+}{'?\-.]+@((?!_)[\w\d\-.])*\.[\w\d]+$/;
export const PHONE_REGEX = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
export const INTEGER_REGEX = /^[1-9]\d*$/;
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const bodyWithAuthHeader = async () => ({
  headers: {
    Authorization: await User.getToken(),
  },
});

export const setLocalStorageData = (key, data) => {
  localStorage.setItem(key, data);
};
export const getLocalStorageData = (key) => localStorage.getItem(key);

export const dateTimeMomentFormat = "MMM Do YY HH:mm:ss";
export const dateMomentFormat = "MMM Do YY";
export const defaultDateFormat = "YYYY-MM-DD";

export const NOMINATION_TYPE_MEMBER = {
  text: "Member",
  value: "MEMBER",
};
export const NOMINATION_TYPE_MANAGER = {
  text: "Manager",
  value: "MANAGER",
};
export const NOMINATION_TYPES = [
  NOMINATION_TYPE_MEMBER,
  NOMINATION_TYPE_MANAGER,
];
export const NOMINATION_TYPES_BY_VALUE = lodash.keyBy(NOMINATION_TYPES, "value");

export const TEXT_FIELD_COMPONENT_TYPE = "textField";
export const AUTOCOMPLETE_COMPONENT_TYPE = "vAutocomplete";
export const COMBOBOX_COMPONENT_TYPE = "vCombobox";
export const CHECKBOX_COMPONENT_TYPE = "vCheckbox";
export const TEXTAREA_COMPONENT_TYPE = "vTextarea";
export const DOUBLE_FIELDS_TYPE = "doubleTextFields";

export const isValueForHideAnyParams = (
  itemParam,
  paramComponents,
  targetParam,
  displayValue,
  value,
) => {
  let component;
  if (paramComponents) {
    component = paramComponents.find(
      (childComponent) => childComponent.pComponentData.value === targetParam,
    );
  }

  return (component && component.valueInField === displayValue)
    || (!component && itemParam[targetParam] === value);
};

export const HOST_STATUS_ACTIVE = "ACTIVE";
export const DISPLAY_HOST_STATUS_ACTIVE = "Active";
export const HOST_STATUS_DRAFT = "DRAFT";
export const DISPLAY_HOST_STATUS_DRAFT = "Draft";
export const HOST_STATUS_INACTIVE = "INACTIVE";
export const DISPLAY_HOST_STATUS_INACTIVE = "Inactive";

export const DISPLAY_NOMINATION_STATE_NOT_NOMINATED = "Not Nominated";

export const TEAM_MANAGER_STATE_ACCEPTED = {
  text: "Accepted",
  value: "ACCEPTED",
};
export const TEAM_MANAGER_STATE_CANCELED = {
  text: "Cancelled",
  value: "CANCELED",
};
export const TEAM_MANAGER_STATE_DECLINED = {
  text: "Declined",
  value: "DECLINED",
};
export const TEAM_MANAGER_STATE_DRAFT = {
  text: "Draft",
  value: "DRAFT",
};
export const TEAM_MANAGER_STATE_FINAL_INVITE = {
  text: "Final Invite",
  value: "FINAL_INVITE",
};
export const TEAM_MANAGER_STATE_INITIAL_INVITE = {
  text: "Initial Invite",
  value: "INITIAL_INVITE",
};
export const TEAM_MANAGER_STATE_NOMINATED = {
  text: "Nominated",
  value: "NOMINATED",
};
export const TEAM_MANAGER_STATE_EXPIRED = {
  text: "Expired",
  value: "EXPIRED",
};
export const TEAM_MANAGER_STATE_REMOVED = {
  text: DISPLAY_NOMINATION_STATE_NOT_NOMINATED,
  value: "REMOVED",
};

export const TEAM_MANAGER_STATES = [
  TEAM_MANAGER_STATE_ACCEPTED,
  TEAM_MANAGER_STATE_CANCELED,
  TEAM_MANAGER_STATE_DECLINED,
  TEAM_MANAGER_STATE_DRAFT,
  TEAM_MANAGER_STATE_FINAL_INVITE,
  TEAM_MANAGER_STATE_INITIAL_INVITE,
  TEAM_MANAGER_STATE_NOMINATED,
  TEAM_MANAGER_STATE_EXPIRED,
  TEAM_MANAGER_STATE_REMOVED,
];

export const NOMINATION_STATE_ACCEPTED = {
  text: "Accepted",
  value: "ACCEPTED",
};
export const NOMINATION_STATE_RESIGNED = {
  text: "Resigned",
  value: "RESIGNED",
};
export const NOMINATION_STATE_APPROVED = {
  text: "Approved",
  value: "APPROVED",
};
export const NOMINATION_STATE_PROMOTED = {
  text: "Promoted",
  value: "PROMOTED",
};
export const NOMINATION_STATE_CANCELED = {
  text: "Canceled",
  value: "CANCELED",
};
export const NOMINATION_STATE_DECLINED = {
  text: "Declined",
  value: "DECLINED",
};
export const NOMINATION_STATE_DENIED = {
  text: "Denied",
  value: "DENIED",
};
export const NOMINATION_STATE_DRAFT = {
  text: "Draft",
  value: "DRAFT",
};
export const NOMINATION_STATE_EXPIRED = {
  text: "Expired",
  value: "EXPIRED",
};
export const NOMINATION_STATE_FINAL_INVITE = {
  text: "Final Invite",
  value: "FINAL_INVITE",
};
export const NOMINATION_STATE_INITIAL_INVITE = {
  text: "Initial Invite",
  value: "INITIAL_INVITE",
};
export const NOMINATION_STATE_NOMINATED = {
  text: "Nominated",
  value: "NOMINATED",
};
export const NOMINATION_STATE_REMOVED = {
  text: DISPLAY_NOMINATION_STATE_NOT_NOMINATED,
  value: "REMOVED",
};

export const NOMINATION_STATES = [
  NOMINATION_STATE_ACCEPTED,
  NOMINATION_STATE_RESIGNED,
  NOMINATION_STATE_APPROVED,
  NOMINATION_STATE_PROMOTED,
  NOMINATION_STATE_CANCELED,
  NOMINATION_STATE_DECLINED,
  NOMINATION_STATE_DENIED,
  NOMINATION_STATE_DRAFT,
  NOMINATION_STATE_EXPIRED,
  NOMINATION_STATE_FINAL_INVITE,
  NOMINATION_STATE_INITIAL_INVITE,
  NOMINATION_STATE_NOMINATED,
  NOMINATION_STATE_REMOVED,
];

export const SUPER_ADMIN_USER_TYPE = "SUPER_ADMIN";
export const SUPER_ADMIN_DISPLAY_USER_TYPE = "Super Admin";
export const STANDARD_USER_TYPE = "STANDARD";
export const STANDARD_DISPLAY_USER_TYPE = "Standard";
export const USER_TYPES_DATA = [
  {
    text: SUPER_ADMIN_DISPLAY_USER_TYPE,
    value: SUPER_ADMIN_USER_TYPE,
  },
  {
    text: STANDARD_DISPLAY_USER_TYPE,
    value: STANDARD_USER_TYPE,
  },
];

export const TEAM_ACTIVE_STATUS = "ACTIVE";
export const TEAM_DRAFT_STATUS = "DRAFT";
export const TEAM_PAUSED_STATUS = "PAUSED";
export const TEAM_INTERIM_STATUS = "INTERIM";
export const TEAM_TERMINATED_STATUS = "TERMINATED";

export const TEAM_STATUSES = [
  {
    text: "Active",
    value: TEAM_ACTIVE_STATUS,
  },
  {
    text: "Draft",
    value: TEAM_DRAFT_STATUS,
  },
  {
    text: "Paused",
    value: TEAM_PAUSED_STATUS,
  },
  {
    text: "Interim",
    value: TEAM_INTERIM_STATUS,
  },
  {
    text: "Terminated",
    value: TEAM_TERMINATED_STATUS,
  },
];

export const DAY_VALID_FOR_UNIT = "DAY";
export const DAY_DISPLAY_VALID_FOR_UNIT = "Day";
export const MONTH_VALID_FOR_UNIT = "MONTH";
export const MONTH_DISPLAY_VALID_FOR_UNIT = "Month";
export const YEAR_VALID_FOR_UNIT = "YEAR";
export const YEAR_DISPLAY_VALID_FOR_UNIT = "Year";
export const VALID_FOR_UNIT_DATA = [
  {
    text: DAY_DISPLAY_VALID_FOR_UNIT,
    value: DAY_VALID_FOR_UNIT,
  },
  {
    text: MONTH_DISPLAY_VALID_FOR_UNIT,
    value: MONTH_VALID_FOR_UNIT,
  },
  {
    text: YEAR_DISPLAY_VALID_FOR_UNIT,
    value: YEAR_VALID_FOR_UNIT,
  },
];

export const getAllDataFromCustomInputComponents = (components, notRequiredParams = {}) => {
  let data = { ...notRequiredParams };
  components.filter((item) => item.pComponentData.visible)
    .forEach((item) => (data = Object.assign(data, item.saveNewValue())));
  return data;
};

export const paginateTable = (data, localStoragePage, tableInited) => {
  if (tableInited) setLocalStorageData(localStoragePage, data.itemsPerPage);

  return tableInited ? data.itemsPerPage : (getLocalStorageData(localStoragePage)?.split(",") || [25])[0];
};

export const HOST_ADD_EDIT_ITEM = "Host";
export const TEAM_ADD_EDIT_ITEM = "Team";

export const STRING_VALUE_TYPE = "string";
export const EMAIL_VALUE_TYPE = "email";
export const PHONE_VALUE_TYPE = "phone";
export const AUTOCOMPLETE_VALUE_TYPE = "autocomplete";
export const COMBOBOX_VALUE_TYPE = "combobox";
export const INTEGER_VALUE_TYPE = "integer";
export const CHECKBOX_VALUE_TYPE = "checkbox";
export const INSTANT_VALUE_TYPE = "instant";

export const HOST_PARAMS = [
  {
    value: "name",
    display: "Name",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: true,
    visible: true,
    maxLength: 255,
  },
  {
    value: "contactName",
    display: "Contact Name",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "contactEmail",
    display: "Contact Email",
    type: EMAIL_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "contactPhone",
    display: "Contact Phone",
    type: PHONE_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "primaryAddress",
    display: "Primary Address",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "secondaryAddress",
    display: "Secondary Address",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "zip",
    display: "Zip",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "state",
    display: "State",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "city",
    display: "City",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
  {
    value: "country",
    display: "Country",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
    maxLength: 255,
  },
];

export const TEAM_PARAMS = [
  {
    value: "name",
    display: "Name",
    type: STRING_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: true,
    visible: true,
  },
  {
    value: "managerEmail",
    display: "Manager email",
    type: EMAIL_VALUE_TYPE,
    componentType: TEXT_FIELD_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
  },
  {
    value: "description",
    display: "Description",
    type: STRING_VALUE_TYPE,
    componentType: TEXTAREA_COMPONENT_TYPE,
    editable: false,
    required: false,
    visible: true,
  },
];

export const ALERT_TYPE_ERROR = {
  value: "ERROR",
  text: "Error",
  color: "red--text",
};
export const ALERT_TYPE_WARN = {
  value: "WARN",
  text: "Warn",
  color: "orange--text",
};
export const ALERT_TYPE_INFO = {
  value: "INFO",
  text: "Info",
  color: "black--text",
};
export const ALERT_TYPE_FEATURE = {
  value: "FEATURE",
  text: "Feature",
  color: "indigo--text",
};
export const ALERT_TYPE_SUPPORT = {
  value: "SUPPORT",
  text: "Support",
  color: "deep-orange--text",
};

export const ALERT_TYPES = lodash.keyBy(
  [ALERT_TYPE_ERROR, ALERT_TYPE_WARN, ALERT_TYPE_INFO, ALERT_TYPE_FEATURE, ALERT_TYPE_SUPPORT], "value",
);

export const copyText = (text) => {
  try {
    navigator.clipboard.writeText(text);
    Vue.toasted.success("Text copied!", {
      duration: 3000,
    });
  } catch (e) {
    Vue.toasted.error("Could not copy text!", { ...dismissAction });
  }
};

export const logAndExtractMessage = (e) => {
  console.error(e);
  let message = "";

  if ((e.response || {}).data) {
    message = e.response.data.message;
  } else if (e.message) {
    message = e.message;
  } else {
    message = e;
  }

  return message;
};

export const getSpaceId = () => process.env.VUE_APP_SPACE_ID || "ecb0e1c1-261d-45f1-9195-245f0682ef90";

export const getUsername = (email) => getUuid(email.trim().toLowerCase(), getSpaceId(), 5);
