import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "../utils/utils";
import { Nomination } from "../model/nomination.model";

const getApiPath = (id) => `/teams/${id}/nominations`;

export default {

  namespaced: true,
  state: {
    lastLoadedPage: "",
    currentPageLoading: false,
    currentPage: {
      nominations: [],
      teamId: "",
      index: 0,
      size: 25,
    },
    displayNominations: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageNominations(state, nominationsDto) {
      state.currentPage.nominations = [...nominationsDto];
      state.displayNominations = state.currentPage
        .nominations.map((item) => Nomination.fromDto(item));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    reloadPage({ state, dispatch }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      if (!currentPage.teamId) { return; }

      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount", currentPage.teamId);

        const nominationsDto = await API.get("core", getApiPath(currentPage.teamId), body);
        commit("updateCurrentPageNominations", nominationsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }, teamId) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${getApiPath(teamId)}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async saveNomination({ commit, dispatch, state }, { nomination, teamId }) {
      const body = { ...await bodyWithAuthHeader(), body: nomination };

      try {
        await API.post("core", getApiPath(teamId), body);

        if (!nomination.id) {
          commit("updateTotalCount", state.totalCount + 1);
        }

        dispatch("reloadPage");

        Vue.toasted.success(`${nomination.email} successfully added!`, {
          duration: 3000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async kickManager({ dispatch }, { team, remain }) {
      try {
        await API.post("core", `/teams/${team.id}/resign-manager`, {
          ...(await bodyWithAuthHeader()),
          queryStringParameters: {
            remain,
          },
        });

        dispatch("reloadPage");

        Vue.toasted.success(`${team.managerEmail} successfully kicked from ${team.name}!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteNomination({ dispatch }, nomination) {
      const body = await bodyWithAuthHeader();
      try {
        await API.del("core", `/teams/nominations/${nomination.id}`, body);
        dispatch("reloadPage");
        Vue.toasted.success(`${nomination.user.firstName} ${nomination.user.lastName} successfully deleted!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
