import AlertInfoPage from "./views/AlertInfoPage";
import AlertsPage from "./views/AlertsPage";
import ForbiddenPage from "./views/ForbiddenPage";
import HostInfoPage from "./views/HostInfoPage";
import LoginPage from "./views/LoginPage";
import ActiveHostPage from "./views/ActiveHostPage";
import RevertRecoveryInfoPagePage from "./views/RevertRecoveryInfoPage";
import HostsPage from "./views/HostsPage";
import TeamInfoPage from "./views/TeamInfoPage";
import UserProfilePage from "./views/UserProfilePage";
import UsersPage from "./views/UsersPage";
import ProfileInfoPage from "./views/ProfileInfoPage";
import RegistrationPage from "./views/RegistrationPage";
import EmailTemplatesPage from "@/views/EmailTemplatesPage";
import CreateHostPage from "@/views/CreateHostPage";
import UserInvitationsPage from "@/views/UserInvitationsPage";
import TeamRolesPage from "@/views/TeamRolesPage";

export const pages = [
  AlertInfoPage,
  AlertsPage,
  HostsPage,
  ForbiddenPage,
  RevertRecoveryInfoPagePage,
  HostInfoPage,
  ActiveHostPage,
  LoginPage,
  TeamInfoPage,
  UserProfilePage,
  UsersPage,
  ProfileInfoPage,
  RegistrationPage,
  EmailTemplatesPage,
  CreateHostPage,
  UserInvitationsPage,
  TeamRolesPage,
].filter((it) => it && it.routing);
