export const TEAM_ROLES_SPECIAL_ENTITIES = [
  "AUDIT",
  "LOCKING",
  "PERMISSIONS",
];

export const TEAM_ROLES_ENTITY_ACTION_CREATE = {
  text: "Create",
  value: "CREATE",
};
export const TEAM_ROLES_ENTITY_ACTION_READ = {
  text: "Read",
  value: "READ",
};
export const TEAM_ROLES_ENTITY_ACTION_UPDATE = {
  text: "Update",
  value: "UPDATE",
};
export const TEAM_ROLES_ENTITY_ACTION_DELETE = {
  text: "Delete",
  value: "DELETE",
};

export const TEAM_ROLES_ENTITY_ACTION_NONE = {
  text: "None",
  value: "NONE",
};

export const TEAM_ROLES_ENTITY_ACTIONS = [
  TEAM_ROLES_ENTITY_ACTION_CREATE,
  TEAM_ROLES_ENTITY_ACTION_READ,
  TEAM_ROLES_ENTITY_ACTION_UPDATE,
  TEAM_ROLES_ENTITY_ACTION_DELETE,
  TEAM_ROLES_ENTITY_ACTION_NONE,
];

export const TEAM_ROLES_FILE_ACTION_CREATE = {
  text: "Create",
  value: "CREATE",
};
export const TEAM_ROLES_FILE_ACTION_READ = {
  text: "Read",
  value: "READ",
};
export const TEAM_ROLES_FILE_ACTION_UPDATE = {
  text: "Update",
  value: "UPDATE",
};
export const TEAM_ROLES_FILE_ACTION_DELETE = {
  text: "Delete",
  value: "DELETE",
};

export const TEAM_ROLES_FILE_ACTION_NONE = {
  text: "None",
  value: "NONE",
};

export const TEAM_ROLES_FILE_ACTIONS = [
  TEAM_ROLES_FILE_ACTION_CREATE,
  TEAM_ROLES_FILE_ACTION_READ,
  TEAM_ROLES_FILE_ACTION_UPDATE,
  TEAM_ROLES_FILE_ACTION_DELETE,
  TEAM_ROLES_FILE_ACTION_NONE,
];

export const TEAM_ROLES_ACTION_HOLD_TEAM = {
  text: "Hold Team",
  value: "HOLD_TEAM",
};
export const TEAM_ROLES_ACTION_NOMINATE_MEMBER = {
  text: "Nominate Member",
  value: "NOMINATE_MEMBER",
};
export const TEAM_ROLES_ACTION_APPROVE_MEMBER_NOMINATION = {
  text: "Approve Member Nomination",
  value: "APPROVE_MEMBER_NOMINATION",
};
export const TEAM_ROLES_ACTION_DENY_MEMBER_NOMINATION = {
  text: "Deny Member Nomination",
  value: "DENY_MEMBER_NOMINATION",
};
export const TEAM_ROLES_ACTION_REMOVE_TEAM_MEMBERSHIP = {
  text: "Remove Team Membership",
  value: "REMOVE_TEAM_MEMBERSHIP",
};
export const TEAM_ROLES_ACTION_UPDATE_TEAM_MEMBERSHIP_ROLES = {
  text: "Update Team Membership Roles",
  value: "UPDATE_TEAM_MEMBERSHIP_ROLES",
};

export const TEAM_ROLES_ACTIONS = [
  TEAM_ROLES_ACTION_HOLD_TEAM,
  TEAM_ROLES_ACTION_NOMINATE_MEMBER,
  TEAM_ROLES_ACTION_APPROVE_MEMBER_NOMINATION,
  TEAM_ROLES_ACTION_DENY_MEMBER_NOMINATION,
  TEAM_ROLES_ACTION_REMOVE_TEAM_MEMBERSHIP,
  TEAM_ROLES_ACTION_UPDATE_TEAM_MEMBERSHIP_ROLES,
];
