import { ALERT_TYPES } from "../utils/utils";

export class Alert {
  id

  alertType

  message

  data

  description

  userId

  viewerUserId

  teamId

  traceId

  updatedAt

  createdAt

  constructor(
    id,
    alertType,
    message,
    data,
    description,
    userId,
    viewerUserId,
    teamId,
    traceId,
    updatedAt,
    createdAt,
  ) {
    this.id = id;
    this.alertType = alertType;
    this.message = message;
    this.data = data;
    this.description = description;
    this.userId = userId;
    this.viewerUserId = viewerUserId;
    this.teamId = teamId;
    this.traceId = traceId;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  static fromDto(dto) {
    return new Alert(
      dto.id,
      ALERT_TYPES[dto.alertType],
      dto.message,
      dto.data,
      dto.description,
      dto.userId,
      dto.viewerUserId,
      dto.teamId,
      dto.traceId,
      dto.updatedAt,
      dto.createdAt,
    );
  }
}
