import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment";

export default {
  install: () => {
    momentDurationFormatSetup(moment);
    // eslint-disable-next-line no-unused-expressions
    typeof moment.duration.fn.format === "function";
    // eslint-disable-next-line no-unused-expressions
    typeof moment.duration.format === "function";
  },
};
