import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { TeamRole } from "@/model/team-role";

const API_PATH = "/team-roles";

export default {

  namespaced: true,
  state: {
    currentPageLoading: false,
    currentPage: {
      teamRoles: [],
      index: 0,
      size: 25,
    },
    displayTeamRoles: [],
    totalCount: 0,
    entities: [],
    filesTypes: [],
  },
  mutations: {
    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageTeamRoles(state, dto) {
      state.currentPage.teamRoles = [...dto];
      state.displayTeamRoles = state.currentPage.teamRoles.map((role) => TeamRole.fromDto(role, state.entities, state.filesTypes));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },

    updateEntities(state, entitiesDto) {
      state.entities = entitiesDto.map(it => ({
        value: it,
        // will prepare APPLICATION and NEW_APPLICATION to Application and New Application
        text: it.split("_").map(word => (word[0] + word.toLowerCase().slice(1))).join(" "),
      }));
    },

    updateFilesTypes(state, filesTypesDto) {
      state.filesTypes = filesTypesDto.map(it => ({
        value: it,
        // will prepare APPLICATION and NEW_APPLICATION to Application and New Application
        text: it.split("_").map(word => (word[0] + word.toLowerCase().slice(1))).join(" "),
      }));
    },
  },
  actions: {

    reloadPage({ state, dispatch }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadEntities({ commit }) {
      const body = {
        ...await bodyWithAuthHeader(),
      };

      try {
        const entitiesDto = await API.get("core", "/entities/supported-types", body);
        commit("updateEntities", entitiesDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadFilesTypes({ commit }) {
      const body = {
        ...await bodyWithAuthHeader(),
      };

      try {
        const filesTypesDto = await API.get("core", "/files/supported-types", body);
        commit("updateFilesTypes", filesTypesDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadSupportedData({ state, dispatch }) {
      await Promise.all([
        !state.entities.length && dispatch("loadEntities"),
        !state.filesTypes.length && dispatch("loadFilesTypes"),
      ]);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadSupportedData");

        await dispatch("loadTotalCount");

        const teamDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageTeamRoles", teamDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
      commit("endLoadingPage");
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addOrSaveTeamRole({ commit, dispatch, state }, teamRole) {
      const body = { ...await bodyWithAuthHeader(), body: teamRole };

      try {
        await (teamRole.id
          ? API.put("core", `${API_PATH}/${teamRole.id}`, body)
          : API.post("core", API_PATH, body));

        if (!teamRole.id) {
          commit("updateTotalCount", state.totalCount + 1);
        }

        dispatch("reloadPage");

        Vue.toasted.success(`${teamRole.name} successfully ${teamRole.id ? "updated" : "added"}!`, {
          duration: 5000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteTeamRole({ commit, dispatch, state }, teamRole) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${teamRole.id}`, body);
        commit("updateTotalCount", state.totalCount - 1);
        dispatch("reloadPage");
        Vue.toasted.success(`${teamRole.name} successfully deleted!`, {
          duration: 5000,
        });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async loadAllRoles({ state, dispatch }) {
      try {
        await dispatch("loadSupportedData");
        const body = await bodyWithAuthHeader();
        const rolesDto = await API.get("core", API_PATH, body);
        return rolesDto.map((role) => TeamRole.fromDto(role, state.entities, state.filesTypes));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
