import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "../utils/utils";
import { Alert } from "../model/alert.model";

export const API_PATH = "/alerts";

export default {

  namespaced: true,
  state: {

    currentPageLoading: false,
    displayAlerts: [],
    selectedAlert: undefined,
    currentPage: {
      alerts: [],
      index: 0,
      size: 25,
    },
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageAlerts(state, alertsDto) {
      state.currentPage.alerts = [...alertsDto];
      state.displayAlerts = state.currentPage.alerts.map((host) => Alert.fromDto(host));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
    updateSelectedAlert(state, alert) {
      state.selectedAlert = alert;
    },
  },
  actions: {
    reloadPage({ dispatch, state }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({ commit, dispatch }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount");

        const alertsDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageAlerts", alertsDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }) {
      const body = await bodyWithAuthHeader();

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async getAlertById({ commit }, alertId) {
      const body = await bodyWithAuthHeader();

      try {
        const result = await API.get("core", `${API_PATH}/${alertId}`, body);
        commit("updateSelectedAlert", Alert.fromDto(result));
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
