<template>
  <EmailTemplates />
</template>

<script>
import EmailTemplates from "@/components/email-templates/EmailTemplates";

export default {
  name: "EmailTemplatesPage",

  routing: {
    routeName: "EmailTemplatesPage",
    navName: "Email Templates",
    icon: "mdi-email",
    path: "/email-templates",
    hasAccess: (user) => user.isSuperAdmin,
  },

  components: {
    EmailTemplates,
  },
};
</script>

<style scoped>

</style>
