import { Team } from "@/model/team.model";
import { User } from "@/model/user.model";
import { TeamRole, TeamRoleEntity, TeamRoleFile } from "@/model/team-role";
import { TeamMembershipDto } from "@/model/types";

export class TeamMembership {
  constructor(
    public id: string,
    public user: User,
    public roles: TeamRole[],
    public team: Team,
  ) {}

  copy(): TeamMembership {
    return new TeamMembership(this.id, this.user, [...this.roles], this.team);
  }

  static fromDto(dto: TeamMembershipDto, entities: TeamRoleEntity[], filesTypes: TeamRoleFile[]): TeamMembership {
    return new TeamMembership(
      dto.id,
      User.fromDto(dto.user),
      dto.roles.map((it) => TeamRole.fromDto(it, entities, filesTypes)),
      Team.fromDto(dto.team),
    );
  }
}
