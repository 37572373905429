
import { debounce } from "lodash";
import {
  Component, Vue, Watch,
} from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import {
  EMAIL_TEMPLATE_SMALL_TEXTAREA,
  EMAIL_TEMPLATE_BIG_TEXTAREA,
} from "@/utils/email-templates-utils";
import EmailTemplateField from "@/components/email-templates/EmailTemplateField.vue";
import { EmailType } from "@/model/email/types";
import { EmailTemplate } from "@/model/email/email-template.model";
import { EmailRepresent } from "@/model/email/email-represent.model";
import { getCoreModuleNamespace, getEmailTemplatesModuleNamespace } from "@/store/store.utils";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

@Component({
  components: {
    EmailTemplateField,
  },
})

export default class EmailTemplates extends Vue {
  isValidText = false;

  loadingOnChangeType = false;

  selectedType: EmailType | null = null;

  currentEmailTemplate: EmailTemplate | null = null;

  EMAIL_TEMPLATE_SMALL_TEXTAREA = EMAIL_TEMPLATE_SMALL_TEXTAREA;

  EMAIL_TEMPLATE_BIG_TEXTAREA = EMAIL_TEMPLATE_BIG_TEXTAREA;

  @State("drawer", getCoreModuleNamespace())
  drawer!: boolean;

  @State("isMobile", getCoreModuleNamespace())
  isMobile!: boolean;

  @State("emailTypes", getEmailTemplatesModuleNamespace())
  emailTypes!: EmailType[];

  @State("emailTemplate", getEmailTemplatesModuleNamespace())
  emailTemplate!: EmailTemplate | null;

  @State("loading", getEmailTemplatesModuleNamespace())
  loading!: boolean;

  @State("emailRepresent", getEmailTemplatesModuleNamespace())
  emailRepresent!: EmailRepresent | null;

  @State("emailRepresentError", getEmailTemplatesModuleNamespace())
  emailRepresentError!: string | null;

  @State("representLoading", getEmailTemplatesModuleNamespace())
  representLoading!: boolean;

  @Mutation("updateEmailTemplate", getEmailTemplatesModuleNamespace())
  updateEmailTemplate!: (data: EmailTemplate | null) => void

  @Mutation("updateEmailRepresent", getEmailTemplatesModuleNamespace())
  updateEmailRepresent!: (data: EmailRepresent | null) => void

  @Action("loadEmail", getEmailTemplatesModuleNamespace())
  loadEmail!: (data: EmailType) => void

  @Action("representEmail", getEmailTemplatesModuleNamespace())
  representEmail!: (data: EmailTemplate) => Promise<void>

  @Action("loadEmailTypes", getEmailTemplatesModuleNamespace())
  loadEmailTypes!: () => Promise<void>

  @Action("saveEmailTemplate", getEmailTemplatesModuleNamespace())
  saveEmailTemplate!: (data: EmailTemplate) => Promise<void>

  @Watch("emailTemplate")
  onUpdEmailTemplate(): void {
    this.currentEmailTemplate = this.emailTemplate ? this.emailTemplate.clone() : null;
    this.loadRepresent();
  }

  get loadingSave(): boolean {
    return this.loadingOnChangeType
        || this.representLoading;
  }

  get disableSave(): boolean {
    return this.loading
        || this.loadingOnChangeType
        || this.representLoading
        || !this.isValidText
        || !!this.emailRepresentError
        || !this.currentEmailTemplate
        || (
            this.currentEmailTemplate?.subject === this.emailTemplate?.subject
            && this.currentEmailTemplate?.body === this.emailTemplate?.body
        );
  }

  loadRepresentDebounce = debounce((isValidText: boolean) => {
    if (isValidText) {
      this.loadRepresent();
    }
  }, 500)

  async save() {
    if (!this.currentEmailTemplate) return;

    try {
      await this.saveEmailTemplate(this.currentEmailTemplate);
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }
  }

  async onChangeSelectedType() {
    if (
      this.selectedType
        && this.selectedType !== this.currentEmailTemplate?.type
    ) {
      this.updateRouteWithSelectedType();

      this.loadingOnChangeType = true;
      this.updateEmailTemplate(null);
      this.updateEmailRepresent(null);
      try {
        await this.loadEmail(this.selectedType);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.loadingOnChangeType = false;
    }
  }

  updateRouteWithSelectedType() {
    if (!this.selectedType) return;

    const selectedTypeToUrl = this.selectedType.value.toLowerCase();

    if (this.$route.query.type !== selectedTypeToUrl) {
      this.$router.push({ query: { type: selectedTypeToUrl } });
    }
  }

  onUpdateEmailBody(text: string) {
    if (this.currentEmailTemplate) {
      this.currentEmailTemplate.body = text;
      if (this.emailTemplate) {
        this.emailTemplate.updatedBody = text;
      }
      this.loadRepresentDebounce(this.isValidText);
    }
  }

  onUpdateEmailSubject(text: string) {
    if (this.currentEmailTemplate) {
      this.currentEmailTemplate.subject = text;
      if (this.emailTemplate) {
        this.emailTemplate.updatedSubject = text;
      }
      this.loadRepresentDebounce(this.isValidText);
    }
  }

  onValidationCheck(isValidText: boolean) {
    this.isValidText = isValidText;
  }

  async loadRepresent() {
    if (this.currentEmailTemplate) {
      try {
        await this.representEmail(this.currentEmailTemplate);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    }
  }

  async mounted() {
    const typeFromUrl = this.$route.query.type as string | undefined;
    try {
      if (!this.emailTypes.length) {
        await this.loadEmailTypes();
      }

      if (typeFromUrl) {
        const foundType = this.emailTypes.find(it => it.value === typeFromUrl.toUpperCase());
        if (foundType) {
          this.selectedType = foundType;
          await this.onChangeSelectedType();
          return;
        }
      }

      if (this.emailTemplate) {
        this.currentEmailTemplate = this.emailTemplate.cloneWithUpdatedData();
        this.selectedType = this.currentEmailTemplate.type;
        this.updateRouteWithSelectedType();
      }
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }
  }
}
