<template>
  <UserProfile />
</template>

<script>
export default {
  name: "ProfileInfoPage",

  routing: {
    routeName: "ProfileInfoPage",
    path: "/profiles/:id",
    hasAccess: (user) => !!user,
  },

  components: {
    UserProfile: () => import("@/components/user/UserProfile.vue"),
  },
};
</script>

<style scoped>

</style>
