import Vue from "vue";
import { API } from "@/acs-api";
import { bodyWithAuthHeader, logAndExtractMessage } from "@/utils/utils";
import { User } from "@/model/user.model";

const API_PATH = "/users";

export default {

  namespaced: true,
  state: {
    lastLoadedPage: "",
    currentPageLoading: false,
    currentPage: {
      users: [],
      index: 0,
      size: 25,
    },
    displayUsers: [],
    totalCount: 0,
  },
  mutations: {

    startLoadingPage(state, currentPage) {
      state.currentPageLoading = true;
      state.currentPage = currentPage;
    },

    endLoadingPage(state) {
      state.currentPageLoading = false;
    },

    updateCurrentPageUsers(state, usersDto) {
      state.currentPage.users = [...usersDto];
      state.displayUsers = state.currentPage.users.map((user) => User.fromDto(user));
    },

    updateTotalCount(state, newValue) {
      state.totalCount = newValue;
    },
  },
  actions: {

    reloadPage({
      state,
      dispatch,
    }) {
      dispatch("loadPage", state.currentPage);
    },

    async loadPage({
      commit,
      dispatch,
    }, currentPage) {
      commit("startLoadingPage", currentPage);

      const body = {
        ...await bodyWithAuthHeader(),
        queryStringParameters: {
          page: currentPage.index,
          pageSize: currentPage.size,
        },
      };

      try {
        await dispatch("loadTotalCount");

        const userDto = await API.get("core", API_PATH, body);
        commit("updateCurrentPageUsers", userDto);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      } finally {
        commit("endLoadingPage");
      }
    },

    async loadTotalCount({ commit }) {
      const body = {
        ...await bodyWithAuthHeader(),
      };

      try {
        const count = await API.get("core", `${API_PATH}/count`, body);
        commit("updateTotalCount", count);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async addOrSaveUser(context, user) {
      const body = {
        ...await bodyWithAuthHeader(),
        body: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          userType: user.type,
        },
      };

      try {
        const result = await (user.id ? API.put("core", `${API_PATH}/${user.id}`, body) : API.post("core", API_PATH, body));
        Vue.toasted.success(
          `${result.firstName} ${result.lastName} successfully ${
            user.id ? "updated" : "added"
          }!`,
          {
            duration: 5000,
          },
        );
        return User.fromDto({ ...result, hostMemberships: [] });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async activateUser({ dispatch }, user) {
      const body = await bodyWithAuthHeader();

      try {
        await API.put("core", `${API_PATH}/${user.id}/activate`, body);
        dispatch("reloadPage");
        Vue.toasted.success(`${user.firstName} ${user.lastName} successfully activated!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async deleteUser({ dispatch }, user) {
      const body = await bodyWithAuthHeader();

      try {
        await API.del("core", `${API_PATH}/${user.id}`, body);
        dispatch("reloadPage");
        Vue.toasted.success(`${user.firstName} ${user.lastName} successfully deactivated!`,
          {
            duration: 5000,
          });
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },

    async getUserById(context, userId) {
      const body = await bodyWithAuthHeader();

      try {
        const user = await API.get("core", `${API_PATH}/${userId}`, body);
        return User.fromDto(user);
      } catch (e) {
        throw Error(logAndExtractMessage(e));
      }
    },
  },
};
