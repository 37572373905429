import moment from "moment";
import { keyBy } from "lodash";
import { Host } from "./host.model";
import {
  TEAM_ACTIVE_STATUS,
  TEAM_TERMINATED_STATUS,
  TEAM_STATUSES,
  TEAM_MANAGER_STATES,
  DISPLAY_NOMINATION_STATE_NOT_NOMINATED,
} from "@/utils/utils";

const teamStates = keyBy(TEAM_MANAGER_STATES, "value");

export class Team {
  id;

  name;

  status;

  managerEmail;

  managerApprovalState;

  managerViewerUser;

  host;

  description;

  createdAt;

  updatedAt;

  hasResignedNomination;

  hasActiveTeamMembers;

  holdExpiresAt;

  static fromDto(teamDto) {
    const team = new Team();

    team.id = teamDto ? teamDto.id : "";
    team.name = teamDto ? teamDto.name : "";
    team.description = teamDto ? teamDto.description : "";
    team.status = teamDto ? teamDto.status : "";
    team.managerEmail = teamDto ? teamDto.managerEmail : "";
    team.managerApprovalState = teamDto ? teamDto.managerApprovalState : "";
    team.managerViewerUser = teamDto ? teamDto.managerViewerUser : "";
    team.host = teamDto ? Host.fromDto(teamDto.host) : "";
    team.createdAt = teamDto ? teamDto.createdAt : "";
    team.updatedAt = teamDto ? teamDto.updatedAt : "";
    team.hasResignedNomination = teamDto?.hasResignedNomination;
    team.hasActiveTeamMembers = teamDto?.hasActiveTeamMembers;
    team.holdExpiresAt = teamDto?.holdExpiresAt && moment().isSameOrBefore(teamDto?.holdExpiresAt)
      ? teamDto?.holdExpiresAt
      : null;

    return team;
  }

  get isActive() {
    return this.status === TEAM_ACTIVE_STATUS;
  }

  get isTerminated() {
    return this.status === TEAM_TERMINATED_STATUS;
  }

  getTeamDisplayStatus() {
    if (!this.status) return undefined;

    return TEAM_STATUSES.find(it => it.value === this.status)?.text;
  }

  getDisplayManagerState() {
    return teamStates[this.managerApprovalState]?.text || DISPLAY_NOMINATION_STATE_NOT_NOMINATED;
  }
}
