import { keyBy } from "lodash";
import { Host } from "@/model/host.model";
import { HostInvitationDto, HostRole } from "@/model/types";
import { USER_HOST_ROLES } from "@/utils/user-utils";
import { HostMembership } from "@/model/host-membership.model";

export class HostInvitation {
  constructor(
    public id: string,
    public email: string,
    public host: Host,
    public roles: HostRole[],
    public hostMembership: HostMembership | null,
  ) { }

  static fromDto(dto: HostInvitationDto): HostInvitation {
    const roles = keyBy(USER_HOST_ROLES, "value");

    return new HostInvitation(
      dto.id,
      dto.email,
      Host.fromDto(dto.host),
      dto.roles.map(it => roles[it]),
      dto.hostMembership ? HostMembership.fromDto(dto.hostMembership) : null,
    );
  }
}
