//* If you want to ADD the NEW THEME, you need to go to ./theme/ package and
//* then create the new file by prototype already exists

//* These colors are used in the theme of this application.
//* src/plugin/vuetify.js

//* Title of this project created in ./src/components/AppBar.vue

//* Browser title and icon created in ./src/main.js

//* Icons contained in ./public/
//* Logos contained in ./src/assets/

import aquilaTheme from "./theme/aquila-theme";
import delphinusTheme from "./theme/delphinus-theme";

const getTheme = () => {
  switch (process.env.VUE_APP_THEME) {
    case delphinusTheme.name:
      return delphinusTheme;
    case aquilaTheme.name:
      return aquilaTheme;
    default:
      return aquilaTheme;
  }
};

export default getTheme();
