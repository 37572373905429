import Vue from "vue";
import Vuex from "vuex";

import userModule from "./user.store";
import coreModule from "./core.store";
import hostModule from "./hosts.store";
import usersModule from "./users.store";
import teamsModule from "./teams.store";
import hostUsersModule from "./host-users.store";
import nominationModule from "./nomination.store";
import alertsModule from "./alerts.store";
import pagesModule from "./pages.store";
import emailTemplatesModule from "./email-templates.store";
import acceptedMemberNominationsModule from "./accepted-member-nominations.store";
import hostInvitationsModule from "./host-invitations.store";
import userInvitationsModule from "./user-invitations.store";
import teamRolesModule from "./team-roles.store";
import teamMembershipsModule from "./team-memberships.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userModule,
    coreModule,
    hostModule,
    usersModule,
    teamsModule,
    hostUsersModule,
    nominationModule,
    alertsModule,
    pagesModule,
    emailTemplatesModule,
    acceptedMemberNominationsModule,
    hostInvitationsModule,
    userInvitationsModule,
    teamRolesModule,
    teamMembershipsModule,
  },
});
