type Namespace = { namespace: string };

export const getCoreModuleNamespace = (): Namespace => ({ namespace: "coreModule" });
export const getEmailTemplatesModuleNamespace = (): Namespace => ({ namespace: "emailTemplatesModule" });
export const getNominationModuleNamespace = (): Namespace => ({ namespace: "nominationModule" });
export const getAcceptedMemberNominationsModuleNamespace = (): Namespace => ({ namespace: "acceptedMemberNominationsModule" });
export const getTeamsModuleNamespace = (): Namespace => ({ namespace: "teamsModule" });
export const getUserModuleNamespace = (): Namespace => ({ namespace: "userModule" });
export const getHostModuleNamespace = (): Namespace => ({ namespace: "hostModule" });
export const getHostInvitationModuleNamespace = (): Namespace => ({ namespace: "hostInvitationsModule" });
export const getTeamRolesModuleNamespace = (): Namespace => ({ namespace: "teamRolesModule" });
export const getTeamMembershipsModuleNamespace = (): Namespace => ({ namespace: "teamMembershipsModule" });
