import lodash from "lodash";
import {
  EntityPermission, FilePermission, TeamRoleAction, TeamRoleDto, TeamRoleEntity, TeamRoleFile,
} from "@/model/team-role/types";
import {
  TEAM_ROLES_ACTIONS,
  TEAM_ROLES_ENTITY_ACTIONS, TEAM_ROLES_FILE_ACTIONS,
} from "@/utils/team-role";

const entityPermsActions = lodash.keyBy(TEAM_ROLES_ENTITY_ACTIONS, "value");
const filePermsActions = lodash.keyBy(TEAM_ROLES_FILE_ACTIONS, "value");
const roleActions = lodash.keyBy(TEAM_ROLES_ACTIONS, "value");

export class TeamRole {
  constructor(
    public id: string,
    public name: string,
    public entityPermissions: EntityPermission[],
    public filePermissions: FilePermission[],
    public actions: TeamRoleAction[],
  ) {
  }

  copy(): TeamRole {
    return new TeamRole(
      this.id,
      this.name,
      this.entityPermissions.map(({ entity, actions }) => ({
        entity,
        actions: [...actions],
      })),
      this.filePermissions.map(({ fileType, actions }) => ({
        fileType,
        actions: [...actions],
      })),
      [...this.actions],
    );
  }

  toDto(): TeamRoleDto {
    return {
      id: this.id,
      name: this.name,
      entityPermissions: Object.fromEntries(this.entityPermissions.map(it => [it.entity.value, it.actions.map(item => item.value)])),
      filePermissions: Object.fromEntries(this.filePermissions.map(it => [it.fileType.value, it.actions.map(item => item.value)])),
      actions: this.actions.map(it => it.value),
    };
  }

  static fromDto(dto: TeamRoleDto, entities: TeamRoleEntity[], filesTypes: TeamRoleFile[]): TeamRole {
    const entitiesByValue = lodash.keyBy(entities, "value");
    const filesByValue = lodash.keyBy(filesTypes, "value");

    return new TeamRole(
      dto.id,
      dto.name,
      Object.entries(dto.entityPermissions).map(([key, value]) => ({
        entity: entitiesByValue[key],
        actions: value.map(it => entityPermsActions[it]),
      })),
      Object.entries(dto.filePermissions).map(([key, value]) => ({
        fileType: filesByValue[key],
        actions: value.map(it => filePermsActions[it]),
      })),
      dto.actions.map(it => roleActions[it]),
    );
  }
}
