import { IS_MOBILE } from "@/utils/is-mobile";

export default {

  namespaced: true,
  state: {
    drawer: !IS_MOBILE,
    isMobile: IS_MOBILE,
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    drawerLinkClicked(state) {
      if (IS_MOBILE) {
        state.drawer = false;
      }
    },
  },
};
