/* eslint-disable no-useless-escape */

export const EMAIL_TEMPLATE_SMALL_TEXTAREA = "EMAIL_TEMPLATE_SMALL_TEXTAREA";
export const EMAIL_TEMPLATE_BIG_TEXTAREA = "EMAIL_TEMPLATE_BIG_TEXTAREA";

export const REGEX_ENTER = /\r?\n/;
export const REGEX_ENTER_G = /\r?\n/g;
export const REGEX_JOIN_ENTER = "\r\n";
export const REGEX_SIMPLE_TEXT = /(?!<)^[\w\d.,?!*$%@#\s\(\){}\/\[\]]*/;

export const REGEX_CONSTANTS = /\${(((?!\n)[#@\(\),&nbsp;\s':\w\d._\-\[\]!?=]?)*)}?/g;
export const REGEX_EMPTY_CONSTANTS = /(\${(?![#@\(\),&nbsp;\s':\w\d._\-\[\]])}?)/g;
export const REGEX_ALL_CONSTANTS = /(\${([#@\(\),\s':\w\d._\-\[\]]?)+(\s?)(}*)?)/g;

export const REGEX_SCIP_SPACES = /[\s\n]*(?![\w/,.@#"!?=${}()|\-])/g;

export const REGEX_BEFORE_AFTER_WORDS_SPACES = /[\s\n](?![\w/,.@#"!?=${}\(\)\-_|])/g;

export const REGEX_HIGHLIGHTED_TEXT_TAGS = /\[?\(?\${(((?!\n)[#@\(\),&nbsp;\s':\w\d._\-\[\]\!\?\=\+]?)*)}?\)?\]?/g;

export const REGEX_HTML_TAGS = /<(\/?)(["=:\s;\${}\w\@\#\/\(\)\-\+,.?!]*)?\/?>?/g;
export const REGEX_HTML_TAG_START = /<\/?\w*/g;
export const REGEX_HTML_TAG_END = /\/?>/g;

export const REGEX_PARSED_CONSTANT_EXPRESSION = /(?![\dnull])[\w''""]*/g;
export const REGEX_CHECK_HAS_EXPRESSION = /[!=<>](?=\w*)/g;

export const REGEX_STYLES = /\.[\w\-\_\.]*(\s*)?\{(\s*)?[\w\-]*(\s*)?\:(\s*)?[\w\-\d\%]*\;(\s*)?\}?/;
export const REGEX_STYLES_ENTERS = /[{;}]\s*/g;
