import { keyBy } from "lodash";
import { User } from "./user.model";
import {
  NOMINATION_STATE_ACCEPTED,
  NOMINATION_STATE_RESIGNED,
  NOMINATION_TYPE_MEMBER,
  NOMINATION_TYPE_MANAGER,
  NOMINATION_STATES,
} from "@/utils/utils";

const nominationStates = keyBy(NOMINATION_STATES, "value");

export class Nomination {
  id;

  user;

  teamId;

  viewerUsers;

  nominationApprovalState;

  nominationType;

  expiresAt;

  createdAt;

  updatedAt;

  static fromDto(nominationDto) {
    const nomination = new Nomination();

    if (nominationDto) {
      nomination.id = nominationDto.id;
      nomination.user = User.fromDto(nominationDto.user);
      nomination.teamId = nominationDto.teamId;

      nomination.viewerUsers = nominationDto.viewerUsers;

      nomination.nominationApprovalState = nominationDto.nominationApprovalState;
      nomination.nominationType = nominationDto.nominationType;
      nomination.expiresAt = nominationDto.expiresAt;

      nomination.createdAt = nominationDto.createdAt;
      nomination.updatedAt = nominationDto.updatedAt;
    }

    return nomination;
  }

  get isActive() {
    return this.isAccepted && this.viewerUsers.length > 0;
  }

  get isAccepted() {
    return this.nominationApprovalState === NOMINATION_STATE_ACCEPTED.value;
  }

  get isResigned() {
    return this.nominationApprovalState === NOMINATION_STATE_RESIGNED.value;
  }

  get isManager() {
    return this.nominationType === NOMINATION_TYPE_MANAGER.value;
  }

  get isMember() {
    return this.nominationType === NOMINATION_TYPE_MEMBER.value;
  }

  getDisplayApprovalState() {
    return nominationStates[this.nominationApprovalState].text;
  }
}
