<template>
  <Registration />
</template>

<script>
export default {
  name: "RegistrationPage",

  routing: {
    routeName: "RegistrationPage",
    path: "/registration",
    hasAccess: (user) => !user,
    useNoAuthedUserRouterView: true,
  },

  components: {
    Registration: () => import("../components/register/Registration.vue"),
  },
};
</script>
