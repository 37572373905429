<template>
  <Forbidden />
</template>

<script>

export default {
  name: "ForbiddenPage",

  routing: {
    routeName: "ForbiddenPage",
    path: "/forbidden-page",
    hasAccess: (user) => !!user, // TODO: remove this page?
  },

  components: {
    Forbidden: () => import("../components/Forbidden.vue"),
  },
};
</script>
