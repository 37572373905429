<template>
  <UserInvitations />
</template>

<script>
import { store } from "../main";

export default {
  name: "UserInvitationsPage",

  routing: {
    routeName: "UserInvitationsPage",
    path: "/my-invitations",
    navName: "My Invitations",
    icon: "mdi-human-greeting",
    hasAccess: (user) => !!user,
    badge: {
      getValue: () => {
        const targetInvitations = store.state.userInvitationsModule.displayUserInvitations.filter(it => !it.hostMembership).length;
        return targetInvitations > 99 ? "99+" : targetInvitations;
      },
      color: "orange",
    },
  },

  components: {
    UserInvitations: () => import("../components/user-invitations/UserInvitations.vue"),
  },
};
</script>
