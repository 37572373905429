import Vue from "vue";
import Vuetify from "vuetify";
import theme from "../../app-theme.config";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: theme.colors.primary,
        secondary: theme.colors.secondary,
        accent: theme.colors.accent,
        error: theme.colors.error,
        textColor: theme.colors.textColor,
        superLightSecondary: "#e3e2e2",
        lightGrey: "#f5f5f5",
      },
    },
  },
});
